
const envSettings = window as any;


const BASE = envSettings.RUNTIME_API_HOST;

const OportunidadesApi = BASE + "/api/oportunidad";
export const OportunidadesEndpoints = {
	OPORTUNIDADES_CON_FILTRO: OportunidadesApi + "/confiltro",
	ACCIONES_COMERCIALES: OportunidadesApi + "/accionescomerciales",
	SUMA_TONELADAS: OportunidadesApi + "/sumatoneladas",
	DOWNLOAD_EXCEL: OportunidadesApi + "/generarinforme",
	DOWNLOAD_EXCEL_DETALLADO: OportunidadesApi + "/generarinformedetallado",
}

const LicitacionesApi = BASE + "/api/licitacion";
export const LicitacionesEndpoints = {
	LICITACIONES_BY_OPORTUNIDAD_ID: LicitacionesApi + "/oportunidadid",
	LICITACION_BY_CODIGO: LicitacionesApi + "/codigo"
}

const PromotoresApi = BASE + "/api/promotor";
export const PromotoresEndpoints = {
	PROMOTORES_CON_FILTRO: PromotoresApi + "/confiltro"
}

const ProvinciasApi = BASE + "/api/provincia";
export const ProvinciasEndpoints = {
	PROVINCIAS_CON_FILTRO: ProvinciasApi + "/confiltro"
}

const DelegacionesApi = BASE + "/api/delegacion";
export const DelegacionesEndpoints = {
	DELEGACION: DelegacionesApi + "/",
	DELEGACIONES_CON_FILTRO: DelegacionesApi + "/confiltro"
}

const EstadoOportunidadesApi = BASE + "/api/EstadoOportunidad";
export const EstadoOportunidadesEndpoints = {
	ESTADO_OPO: EstadoOportunidadesApi + "/getall",
}

const UnidadesMedidaApi = BASE + "/api/UnidadMedida";
export const UnidadesMedidaEndpoints = {
	DEFAULT_FILTER: UnidadesMedidaApi + "/",
}

const MaterialesApi = BASE + "/api/Material";
export const MaterialesEndpoints = {
	GET_BY_ID: MaterialesApi + "/",
	DEFAULT_FILTER: MaterialesApi + "/",
	CON_FILTRO: MaterialesApi + "/confiltro"
}

const TramosApi = BASE + "/api/Tramo";
export const TramosEndpoints = {
	GET_BY_ID: TramosApi + "/",
	DEFAULT_FILTER: TramosApi + "/",
	CON_FILTRO: TramosApi + "/confiltro"
}

const PlantasApi = BASE + "/api/Planta";
export const PlantasEndpoints = {
	GETALLSINPAGINCACION: PlantasApi + "/getallsinpaginacion",
	DEFAULT_FILTER: PlantasApi + "/",
	CONFILTRO: PlantasApi + "/confiltro"
}

const CodigoPostalApi = BASE + "/api/codigopostal";
export const CodigoPostalEndpoints = {
	CODIGDOPOSTAL_BY_ID: CodigoPostalApi + "/",
	CodigoPostal_CON_FILTRO: CodigoPostalApi + "/confiltro"
}

const ContratistasApi = BASE + "/api/contratista";
export const ContratistasEndpoints = {
	CONTRATISTAS_CON_FILTRO: ContratistasApi + "/confiltro"
}

const ClienteApi = BASE + "/api/cliente";
export const ClienteEndpoints = {
	CON_FILTRO: ClienteApi + "/confiltro",
	CON_ID: ClienteApi + "/",
	CREAR: ClienteApi,
}

const SeguimientoHistoricoClienteApi = BASE + "/api/seguimientocliente";
export const SeguimientoHistoricoClienteEndpoints = {
	CON_FILTRO: SeguimientoHistoricoClienteApi + "/confiltro",
	CREAR: SeguimientoHistoricoClienteApi,
	DELETE: SeguimientoHistoricoClienteApi + "/",
	CON_ID: SeguimientoHistoricoClienteApi + "/",
	DOWNLOAD_EXCEL: SeguimientoHistoricoClienteApi + "/generarinforme",


}

const AjusteApi = BASE + "/api/ajuste";
export const AjusteEndpoints = {
	CREAR: AjusteApi,
	UPDATE: AjusteApi
}

const AdjudicacionApi = BASE + "/api/adjudicacion";
export const AdjudicacionEndpoints = {
	BASE: AdjudicacionApi + "/"

}

const OfertasApi = BASE + "/api/oferta";
export const OfertasEndpoints = {
	BASE_ENPOINT: OfertasApi + "/",
	CON_FILTRO: OfertasApi + "/confiltro",
	CREATE_UPDATE: OfertasApi,
	CLONE: OfertasApi + "/copiaroferta",
	DOWNLOAD_EXCEL: OfertasApi + "/generarinforme",
	SUMA_TONELADAS: OfertasApi + "/sumatoneladas",
	DRIVE_URL: OfertasApi + "/updatedriveurl",
}

const TipoOferta = BASE + "/api/tipooferta";
export const TipoOfertasEndpoints = {
	BASE_ENPOINT: TipoOferta + "/"
}

const Comercial = BASE + "/api/comercial";
export const ComercialEndpoints = {
	BASE_ENPOINT: Comercial + "/",
	CON_FILTRO: Comercial + "/confiltro"
}

const Motivo = BASE + "/api/motivo";
export const MotivosEndpoints = {
	BASE_ENPOINT: Motivo
}

const ContactoClienteApi = BASE + "/api/contacto";
export const ContactoClienteEndpoints = {
	ALL: ContactoClienteApi,
	CON_FILTRO: ContactoClienteApi + "/confiltro",
	CON_FILTRO_CONTACTO: ContactoClienteApi + "/ConFiltroSelectItem",
	CON_ID: ContactoClienteApi + "/",
	CREAR: ContactoClienteApi,
	UPDATE: ContactoClienteApi,
	DELETE: ContactoClienteApi + "/",

}

const FormaPagoApi = BASE + "/api/formapago";
export const FormaPagoEndpoints = {
	CON_FILTRO: FormaPagoApi + "/confiltro",
	CON_ID: FormaPagoApi + "/",
	CREAR: FormaPagoApi,
	UPDATE: FormaPagoApi,
	DELETE: FormaPagoApi + "/",
}

const BancosApi = BASE + "/api/banco";
export const BancosEndpoints = {
	BANCOS_CON_FILTRO: BancosApi + "/confiltro"
}

const SucursalApi = BASE + "/api/sucursal";
export const SucursalEndpoints = {
	SUCURSALES_CON_FILTRO: SucursalApi + "/confiltro",
	SUCURSAL_BY_ID: SucursalApi + "/"
}

const EventCalendarApi = BASE + "/api/eventocalendar";
export const EventCalendarEndpoints = {
	ENVIAR_EVENTO: EventCalendarApi + "/enviareventoscalendar"
}

const SeguimientoClieteApi = BASE + "/api/seguimientoCliente";
export const SeguimientoClienteEndpoints = {
	CON_FILTRO: SeguimientoClieteApi + "/confiltro",
	CON_ID: SeguimientoClieteApi + "/",
	CREAR: SeguimientoClieteApi,
	UPDATE: SeguimientoClieteApi,
	DELETE: SeguimientoClieteApi + "/",
}

const SeguimientoOfertaApi = BASE + "/api/seguimientoOferta";
export const SeguimientoOfertaEndpoints = {
	CON_FILTRO: SeguimientoOfertaApi + "/confiltro",
	CON_ID: SeguimientoOfertaApi + "/",
	CREAR: SeguimientoOfertaApi,
	UPDATE: SeguimientoOfertaApi,
	DELETE: SeguimientoOfertaApi + "/",
}

const TipoSeguimientoClienteApi = BASE + "/api/tipoSeguimientoCliente";
export const TipoSeguimientoClienteEndpoints = {
	ALL: TipoSeguimientoClienteApi + "/"

}

const EstadoOfertaApi = BASE + "/api/estadooferta";
export const EstadoOfertaEndpoints = {
	ALL: EstadoOfertaApi + "/",
	CON_FILTRO: EstadoOfertaApi + "/confiltro",
}

const EstudioApi = BASE + "/api/estudio";
export const EstudioApiEndpoints = {
	ALL: EstudioApi + "/",
	DEFAULT_FILTER: EstudioApi,
	CREAR: EstudioApi,
	UPDATE: EstudioApi,
	DELETE: EstudioApi + "/",
	GET_BY_ID: EstudioApi + "/",
	CON_FILTRO: EstudioApi + "/conFiltro",
	ALL_WITHDISCOUNT: EstudioApi + "/GetAllWithDiscount",
	UPDATE_TARIFAS: EstudioApi,
	UPDATE_DETALLE: EstudioApi + "/update-detalle",
}


const TipoFormaPagoContratoApi = BASE + "/api/tipoformapagocontrato";
export const TipoFormaPagoContratoApiEndpoints = {
	ALL: TipoFormaPagoContratoApi + "/",
}

const ContratoApi = BASE + "/api/contrato";
export const ContratoApiEndpoints = {
	ADD: ContratoApi + "/",
	UPDATE: ContratoApi + "/",
}


const EstudioDetalleApi = BASE + "/api/estudiodetalle";
export const EstudioDetalleApiEndpoints = {
	ALL: EstudioDetalleApi + "/",
	DEFAULT_FILTER: EstudioDetalleApi + "/",
	CREAR: EstudioDetalleApi,
	CREARLIST: EstudioDetalleApi + "/insertarray",
	UPDATELIST: EstudioDetalleApi + "/updatearray",
	UPDATE_DETALLE_ESTUDIO: EstudioDetalleApi + "/update-detalle-estudio",
	UPDATE: EstudioDetalleApi,
	DELETE: EstudioDetalleApi + "/",
	EXPORT: EstudioDetalleApi + "/export",
}

const ElementosEquipoApi = BASE + "/api/unidadobra";
export const ElementosEquipoApiEndpoints = {
	// ALL: ElementosEquipoApi,
	CON_FILTRO: ElementosEquipoApi + "/confiltro",
	// DEFAULT_FILTER:ElementosEquipoApi ,

	ALL_ELEMENTOS: ElementosEquipoApi + "elemento",
	CREAR_ELEMENTO: ElementosEquipoApi + "elemento",
	DELETE_ELEMENTO: ElementosEquipoApi + "elemento/",
	UPDATE_ELEMENTO: ElementosEquipoApi + "elemento",
	ELEMENTO_CON_FILTRO: ElementosEquipoApi + "elemento/confiltro",
	GET_BY_ID: ElementosEquipoApi + "elemento/",
}

const ElementoTipoApi = BASE + "/api/unidadobraelementotipo";
export const ElementoTipoEndpoints = {
	ALL: ElementoTipoApi
}

const EquipoApi = BASE + "/api/equipo";
export const EquipoApiEndpoints = {
	CON_FILTRO: EquipoApi + "/confiltro",
	CON_TARIFA: EquipoApi + "/contarifas",
	ALL_EQUIPOS: EquipoApi,
	CREAR_EQUIPO: EquipoApi,
	UPDATE_EQUIPO: EquipoApi,
	DELETE_EQUIPO: EquipoApi + "/",
	GET_BY_ID_EQUIPO: EquipoApi + "/",
}

const PresupuestoApi = BASE + "/api/presupuesto";
export const PresupuestoApiEndpoints = {
	ALL: PresupuestoApi + "/",
	CON_FILTRO: PresupuestoApi + "/confiltro",
	CON_ID: PresupuestoApi + "/",
	DEFAULT_FILTER: PresupuestoApi,
	CREAR: PresupuestoApi,
	UPDATE: PresupuestoApi,
	DELETE: PresupuestoApi + "/",
	ENVIAR: PresupuestoApi + "/enviarpresupuesto",
	SIMPLEUPDATE: PresupuestoApi + "/simpleUpdate",
	BORRADORCONTRATADO: PresupuestoApi + "/borradorPresupuesto"
}

const PresupuestoClausulasApi = BASE + "/api/presupuestoclausulas";
export const PresupuestoClausulasApiEndpoints = {
	ALL: PresupuestoClausulasApi + "/",
	CON_FILTRO: PresupuestoClausulasApi + "/confiltro",
	DEFAULT_FILTER: PresupuestoClausulasApi,
	CREAR: PresupuestoClausulasApi,
	UPDATE: PresupuestoClausulasApi,
	DELETE: PresupuestoClausulasApi + "/",
}

const UnidadObraApi = BASE + "/api/unidadObra";
export const UnidadObraApiEndpoints = {
	ALL: UnidadObraApi,
	CON_ID: UnidadObraApi + "/",
	CON_FILTRO: UnidadObraApi + "/confiltro",
	DEFAULT_FILTER: UnidadObraApi + "/",
	CREAR: UnidadObraApi,
	UPDATE: UnidadObraApi,
	DELETE: UnidadObraApi + "/",
	GETALLSINPAGINCACION: UnidadObraApi + "/getallsinpaginacion"
}

const UnidadObraElementoApi = BASE + "/api/unidadObraElemento";
export const UnidadObraElementoApiEndpoints = {
	ALL: UnidadObraElementoApi,
	CON_ID: UnidadObraElementoApi + "/",
	CON_FILTRO: UnidadObraElementoApi + "/confiltro",
	DEFAULT_FILTER: UnidadObraElementoApi + "/"
}

const UnidadObraMaterialApi = BASE + "/api/unidadObraMaterial";
export const UnidadObraMaterialApiEndpoints = {
	ALL: UnidadObraMaterialApi,
	CON_ID: UnidadObraMaterialApi + "/",
	DEFAULT_FILTER: UnidadObraMaterialApi + "/",
	CREAR: UnidadObraMaterialApi,
	ADD_LIST: UnidadObraMaterialApi + "/addMateriales",
	CON_FILTRO: UnidadObraMaterialApi + "/confiltro",
	DELETE: UnidadObraMaterialApi + "/"
}

const UnidadObraEquipoApi = BASE + "/api/unidadObraEquipo";
export const UnidadObraEquipoApiEndpoints = {
	ALL: UnidadObraEquipoApi,
	CON_ID: UnidadObraEquipoApi + "/",
	DEFAULT_FILTER: UnidadObraEquipoApi + "/",
	CREAR: UnidadObraEquipoApi,
	ADD_LIST: UnidadObraEquipoApi + "/addEquipos",
	DELETE: UnidadObraEquipoApi + "/"
}

const DescricionUnidadObraApi = BASE + "/api/descripcionUnidadObra";
export const DescripcionUnidadObraApiEndpoints = {
	ALL: DescricionUnidadObraApi + "/",
	CON_ID: DescricionUnidadObraApi + "/",
	DEFAULT_FILTER: DescricionUnidadObraApi + "/",
	CREAR: DescricionUnidadObraApi,
	DELETE: DescricionUnidadObraApi + "/"
}

const ClausulasApi = BASE + "/api/clausula";
export const ClausulasApiEndpoints = {
	CON_FILTRO: ClausulasApi + "/confiltro",
	ALL: ClausulasApi,
	CON_ID: ClausulasApi + "/",
	CREAR: ClausulasApi,
	UPDATE: ClausulasApi,
	DELETE: ClausulasApi + "/"
}

const LibroEstandarApi = BASE + "/api/libroestandar";
export const LibroEstandarApiEndpoints = {
	CON_FILTRO: LibroEstandarApi + "/confiltro",
	ALL: LibroEstandarApi,
	CON_ID: LibroEstandarApi + "/",
	CREAR: LibroEstandarApi,
	UPDATE: LibroEstandarApi,
	DELETE: LibroEstandarApi + "/",
	AUTOGENERAR_LIBRO_ESTANDAR: LibroEstandarApi + "/AutoGenerarLibroEstandar",
	TRASPASAR_LIBRO_ESTANDAR: LibroEstandarApi + "/TranspasarLibroEstandar"
}

const LibroEstandarDetalleApi = BASE + "/api/libroestandardetalle";
export const LibroEstandarDetalleApiEndpoints = {
	CON_FILTRO: LibroEstandarDetalleApi + "/confiltro",
	ALL: LibroEstandarDetalleApi,
	CON_ID: LibroEstandarDetalleApi + "/",
	CREAR: LibroEstandarDetalleApi,
	UPDATE: LibroEstandarDetalleApi,
	DELETE: LibroEstandarDetalleApi + "/",
	DEFAULT_FILTER: LibroEstandarDetalleApi + "/",
	CREARLIST: LibroEstandarDetalleApi + "/insertarray"

}

const EstudioDetalleAvanzadoMaterialApi = BASE + "/api/estudiodetalleavanzadomaterial";
export const EstudioDetalleAvanzadoMaterialEndpoints = {
	DELETE: EstudioDetalleAvanzadoMaterialApi + "/",
}

const EstudioDetalleAvanzadoEquipoApi = BASE + "/api/estudiodetalleavanzadoequipo";
export const EstudioDetalleAvanzadoEquipoEndpoints = {
	DELETE: EstudioDetalleAvanzadoEquipoApi + "/",
}

const EstudioDetalleAvanzadoElementoApi = BASE + "/api/estudiodetalleavanzadoelemento";
export const EstudioDetalleAvanzadoElementoEndpoints = {
	DELETE: EstudioDetalleAvanzadoElementoApi + "/",
}

const EstudioDetalleAvanzadoTrasladoApi = BASE + "/api/estudiodetalleavanzadotraslado";
export const EstudioDetalleAvanzadoTrasladoEndpoints = {
	DELETE: EstudioDetalleAvanzadoTrasladoApi + "/",
}

const EstudioDetalleAvanzadoTramoApi = BASE + "/api/estudiodetalleavanzadotramo";
export const EstudioDetalleAvanzadoTramoEndpoints = {
	DELETE: EstudioDetalleAvanzadoTramoApi + "/",
}

const ClausulasOfertaApi = BASE + "/api/clausulaOferta";
export const ClausulasOfertaApiEndpoints = {
	CON_FILTRO: ClausulasOfertaApi + "/confiltro",
	CON_ID: ClausulasOfertaApi + "/",
	CREAR: ClausulasOfertaApi,
	UPDATE: ClausulasOfertaApi,
	DELETE: ClausulasOfertaApi + "/"
}

const NaturalezasCliente = BASE + "/api/naturalezacliente";
export const NaturalezasClienteEndpoints = {
	BASE_ENPOINT: NaturalezasCliente + "/"
}

const PermisoVentanaApi = BASE + "/api/permisoventana";
export const PermisoVentanaApiEndpoints = {
	GET: PermisoVentanaApi,
	CON_FILTRO: PermisoVentanaApi + "/confiltro"
}

const UserPreferenceAPI = envSettings.RUNTIME_API_PAVASAL+"/UserPreferences/api/";
export const UserPreferenceApiEndpoints = {
	GET: UserPreferenceAPI+"Filter",
	UPSERT: UserPreferenceAPI +"Filter/upsert"
}