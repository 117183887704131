import { MaterialCalculo } from "../../../../../../domain/model/Material";
import UnidadMedida from "../../../../../../domain/model/UnidadMedida";
import { formatNumber } from "../../../../../../utils/NumberUtil";
import { groupByCustom } from "../../../../../../utils/Utilities";
import { EquipoCalculo } from "../../../../../Equipos/domain/model/Equipo";
import Estudio from "../../../../../Estudios/domain/model/Estudio";
import EstudioDetalle from "../../../../../Estudios/domain/model/EstudioDetalle";
import EstudioDetalleAvanzadoExport from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoExport";
import EstudioDetalleAvanzadoMaterial from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoMaterial";
import EstudioDetalleAvanzadoTraslado from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoTraslado";
import EstudioDetalleExport from "../../../../../Estudios/domain/model/EstudioDetalleExport";
import EstudioDetalleTotalesGrupo from "../../../../../Estudios/domain/model/EstudioDetalleTotalesGrupo";
import EstudioExport from "../../../../../Estudios/domain/model/EstudioExport";
import Ofertas from "../../../../domain/model/Ofertas";

const CALCULO_AVANZADO_ID_MBC = 1;
const CALCULO_AVANZADO_ID_FRESADO = 2;
const CALCULO_AVANZADO_ID_ZAHORRA = 3;
const CALCULO_AVANZADO_ID_PLANTILLA = 4;
const ZAHORRA_STR: string = "ZAHORRA";


export const medicionByUnidadMedida = (estudioDetalle: EstudioDetalle) => {
    return( 
    (estudioDetalle.estudioDetalleAvanzado != undefined) ?
        (estudioDetalle && estudioDetalle.unidadMedida && estudioDetalle.unidadMedida.id.toString() === "2") ?
        estudioDetalle.medicion :
        (estudioDetalle && estudioDetalle.unidadMedida && estudioDetalle.unidadMedida.id.toString() === "1") ?
            +estudioDetalle.estudioDetalleAvanzado?.material?.find(x => x.orden === estudioDetalle.orden)?.toneladas! :
            +estudioDetalle.estudioDetalleAvanzado?.material?.find(x => x.orden === estudioDetalle.orden)?.volumen!
    : estudioDetalle.medicion
    );
}


export const updateMateriales = (material: MaterialCalculo[],
    index: number,
    densidad: string | undefined,
    espesor: string | undefined,
    superficie: string | undefined,
    toneladas: string | undefined,
    precio: string | undefined,
    equipoTrasladoEuroTm: number,
    totalTramos: number,
    unidadMedida: UnidadMedida,
    unidadObraStr: string | undefined = undefined,
    calculoAvanzadoId?: number
) => {
    material[index].densidad = densidad || densidad == "" ? densidad : material[index].densidad;
    material[index].superficie = superficie || superficie == "" ? superficie : material[index].superficie;
    material[index].precio = precio || precio == "" ? precio : material[index].precio;
    material[index].costeMezcla = +material[index].tmNecesarias * +material[index].precio;
    material[index].espesor = espesor || espesor == "" ? espesor : material[index].espesor;

    if (espesor || material[index].espesor) {
        if (espesor || espesor == "") {
            material[index].espesor = espesor || espesor == "" ? espesor : material[index].espesor;
            material[index].tmNecesarias = ((+material[index].densidad * +espesor * +material[index].superficie) / 100).toFixed(2);
        }
        if ((densidad || superficie) && unidadMedida.id == "2") {
            material[index].tmNecesarias = ((+material[index].densidad * +material[index].espesor * +material[index].superficie) / 100).toFixed(2);
        }
    }

    if (superficie || material[index].superficie) {
        if (superficie || superficie == "") {
            material[index].superficie = superficie || superficie == "" ? superficie : material[index].superficie;
            if (calculoAvanzadoId != CALCULO_AVANZADO_ID_MBC && unidadMedida.id == "1") {
                material[index].tmNecesarias = material[index].tmNecesarias;
            } else {
                material[index].tmNecesarias = ((+material[index].densidad * +material[index].espesor * +superficie) / 100).toFixed(2);
            }
        }
    }

    if (densidad || material[index].densidad) {
        if (densidad || densidad == "") {
            material[index].densidad = densidad || densidad == "" ? densidad : material[index].densidad;
            material[index].tmNecesarias = ((+densidad * +material[index].espesor * +material[index].superficie) / 100).toFixed(2);
        }
    }

    if (toneladas || material[index].tmNecesarias) {
        if (toneladas || toneladas == "") {
            material[index].tmNecesarias = toneladas;
            material[index].espesor = ((+toneladas * 100) / (+material[index].densidad * +material[index].superficie)).toFixed(2);
        }

        if ((densidad || superficie) && unidadMedida.id == "1") {
            material[index].espesor = ((+material[index].tmNecesarias * 100) / (+material[index].densidad * +material[index].superficie)).toFixed(2);
        }
    }

    material[index].preciom2 = +material[index].precio * +material[index].densidad * (+material[index].espesor / 100);
    material[index].totalEurosM2 = +(+material[index].riego + +(+material[index].precio + equipoTrasladoEuroTm + +totalTramos).toFixed(2) * (+material[index].espesor / 100) * +material[index].densidad).toFixed(2);

    if (calculoAvanzadoId === CALCULO_AVANZADO_ID_ZAHORRA) {
        material[index].volumen = +(+(+material[index].espesor / 100).toPrecision(10) * +material[index].superficie).toFixed(2);
        if (material[index].volumen === undefined || isNaN(material[index].volumen ?? 0)) {
            material[index].volumen = 0;
        }
    }

    const totalToneladas = material?.reduce((partialSum, a) => partialSum + +a.tmNecesarias, 0) ?? 0;

    return { materiales: material, totalToneladasMateriales: totalToneladas };
}

export const updateDiasRendimiento = (dias: number, rendimientoCampo: number) => {
    return rendimientoCampo / dias;
}

export const updateDiasEquipo = (dias: string, index: number, equipos: EquipoCalculo[]) => {
    equipos[index].dias = dias;
    equipos[index].costeEquipo = +dias * parseFloat(equipos[index].tarifa);
    return equipos;
}

export const updateEquipos = (dias: string | undefined, unidades: string | undefined, index: number, equipos: EquipoCalculo[], calculoAvanzadoId?: number) => {
    equipos[index].dias = dias || dias == "" ? dias : equipos[index].dias;

    if (calculoAvanzadoId != CALCULO_AVANZADO_ID_MBC) {
        equipos[index].unidades = unidades || unidades == "" ? unidades : equipos[index].unidades;
    } else {
        equipos[index].unidades = "1";
    }

    equipos[index].costeEquipo = (+equipos[index].unidades * +equipos[index].dias) * parseFloat(equipos[index].tarifa);

    return equipos;
}

export const updateDiasTraslado = (dias: string, index: number, traslados: EstudioDetalleAvanzadoTraslado[]) => {
    traslados[index].dias = dias;
    traslados[index].coste = +dias * traslados[index].tarifa;
    return traslados;
}

export const updateTransporte = (precio: string, materiales: MaterialCalculo[], equipoTrasladoEuroTm: number) => {
    materiales.forEach(material => {
        material.totalEurosM2 = +(+material.riego + +(+material.precio + equipoTrasladoEuroTm + +precio).toFixed(2) * (+material.espesor / 100) * +material.densidad).toFixed(2);
    });

    return materiales;
}

const mapToMaterialCalculo = (material: MaterialCalculo | EstudioDetalleAvanzadoMaterial, unidadMedidaId: number): MaterialCalculo => {
    let totales: MaterialCalculo;

    if (material instanceof MaterialCalculo) {
        totales = { ...material };
    } else {
        totales = {
            ...material,
            descripcionDetalle: material.descripcionDetalle ?? "",
            unidadMedida: new UnidadMedida({ id: unidadMedidaId.toString(), descripcion: "" }),
            precio: material.baremo,
            tmNecesarias: material.toneladas,
            totalEurosM2: material.totalEurosM2 ?? 0,
            mezcla: "",
            preciom2: 0,
            costeMezcla: 0
        };
    }

    return totales;
};

export const updateTotales = (
    material: MaterialCalculo | EstudioDetalleAvanzadoMaterial,
    equipoTrasladoEuroTm: number,
    totalTramos: number,
    unidadMedidaId: number,
    calculoAvanzadoId?: number
) => {
    let totales: MaterialCalculo = mapToMaterialCalculo(material, unidadMedidaId);
    totales.precioCosteM2 = +(+(+totales.precio + equipoTrasladoEuroTm + +totalTramos).toFixed(2) * (+material.espesor / 100) * +material.densidad).toFixed(2);
    totales.precioVentaM2 = +(+(totales.totalEurosM2 / material.coeficiente).toPrecision(10)).toFixed(2);
    totales.precioCosteTm = +(+totales.precio + equipoTrasladoEuroTm + +totalTramos).toFixed(2);
    totales.precioVentaTm = +(+(totales.precioCosteTm / material.coeficiente).toPrecision(10)).toFixed(2);
    totales.importeCosteM2SinRiego = +((totales.precioCosteM2) * +material.superficie).toFixed(2);
    totales.importeCosteM2 = +(+(totales.precioCosteM2 + +material.riego).toPrecision(10) * +material.superficie).toFixed(2);
    totales.importeVentaM2 = +(totales.precioVentaM2 * +material.superficie).toFixed(2);
    totales.importeCosteTm = +(totales.precioCosteTm * +totales.tmNecesarias).toFixed(2);
    totales.importeVentaTm = +(totales.precioVentaTm * +totales.tmNecesarias).toFixed(2);

    if (unidadMedidaId > 1) { // M2
        totales.importeCoste = totales.importeCosteM2;
        totales.importeVenta = totales.importeVentaM2;

        if (calculoAvanzadoId === CALCULO_AVANZADO_ID_ZAHORRA) {
            totales.precioCosteM3 = +(totales.precioCosteTm * +material.densidad).toFixed(2);
            totales.precioVentaM3 = +(+(totales.precioCosteM3 / material.coeficiente).toPrecision(10)).toFixed(2);
            totales.importeCosteM3 = +(totales.precioCosteM3 * +material.volumen!).toFixed(2);
            totales.importeVentaM3 = +(totales.precioVentaM3 * +material.volumen!).toFixed(2);
        }
    } else {
        totales.importeCoste = totales.importeCosteTm;
        totales.importeVenta = totales.importeVentaTm;

        if (calculoAvanzadoId === CALCULO_AVANZADO_ID_ZAHORRA) {
            totales.precioCosteM3 = +(totales.precioCosteTm * +material.densidad).toFixed(2);
            totales.precioVentaM3 = +(+(totales.precioCosteM3 / material.coeficiente).toPrecision(10)).toFixed(2);
            totales.importeCosteM3 = +(totales.precioCosteM3 * +material.volumen!).toFixed(2);
            totales.importeVentaM3 = +(totales.precioVentaM3 * +material.volumen!).toFixed(2);
        }
    }

    return totales;
}

export const updateTotalEurosM2 = (material: MaterialCalculo[],
    index: number,
    equipoTrasladoEuroTm: number,
    totalTramos: number
) => {
    material[index].totalEurosM2 = (+(+material[index].riego + +(+material[index].precio + equipoTrasladoEuroTm + +totalTramos).toFixed(2) * (+material[index].espesor / 100) * +material[index].densidad).toFixed(2));
    return material
}

export const generateExcel = (estudioDetalle: EstudioDetalle[], oferta: Ofertas, estudio: Estudio) => {
    let calculoAvanzadoId = estudioDetalle[0].unidadObra?.calculoAvanzadoId;
    let estudioExport: EstudioExport = {} as EstudioExport;
    estudioExport.ofertaId = oferta.id;
    estudioExport.ofertaCodigo = +oferta.codigo;
    estudioExport.clienteDescripcion = oferta.clienteDescripcion;
    estudioExport.delegacionDescripcion = oferta.delegacionDescripcion;
    estudioExport.estudioId = estudio.estudioId!.toString();
    estudioExport.fechaEstudio = new Date(estudio.creationTime);
    estudioExport.obraDescripcion = oferta.obraDescripcion;
    estudioExport.numeroPresupuestoYVersion = estudio.numeroPresupuestoYVersion;
    estudioExport.descripcion = estudio.descripcion;
    estudioExport.estudioDetalle = [];
    let mediaCoeficiente: number = 0;

    //REVISAR FSM
    // Hay que recorrer lo materiales del estudio detalle. Los que tengan totalizarGrupo distinto de null hay que sumar sus toneladas, etc etc. Los que
    // tengan totalizarGrupo == null hay que hacer lo que se hace en el else

    if (estudioDetalle[0].estudioDetalleAvanzado?.material?.find(x => x.totalizarGrupo != undefined)) {

        let estudioGroup = groupByCustom(estudioDetalle[0].estudioDetalleAvanzado?.material, (elemento: MaterialCalculo) => {
            return elemento.totalizarGrupo != undefined ? elemento.totalizarGrupo : []
        });

        estudioGroup.forEach((materialCal: EstudioDetalleAvanzadoMaterial[]) => {
            if (materialCal.find(e => e.totalizarGrupo != undefined)) {
                //updateTotalMaterialesArray(updateTotalMateriales(materialCal.filter(m => m.totalizarGrupo != undefined)));
            }
        })

        estudioExport.toneladas = estudioDetalle[0].estudioDetalleAvanzado?.material?.reduce((partialSum, a) => partialSum + +a.toneladas, 0) ?? 0;
        estudioExport.coeficiente = estudioDetalle[0].estudioDetalleAvanzado?.material?.reduce((partialSum, a) => partialSum + +a.coeficiente, 0) / estudioDetalle[0].estudioDetalleAvanzado?.material.length;
        estudioExport.estudioDetalle = generateExcelTotalizar(estudioDetalle, estudioExport.toneladas);
    } else {
        estudioDetalle.forEach((detalle, index) => {
            let estudioDetalleExport: EstudioDetalleExport = {} as EstudioDetalleExport;
            if (calculoAvanzadoId == CALCULO_AVANZADO_ID_PLANTILLA) {
                estudioDetalleExport.toneladas = detalle.estudioDetalleAvanzado?.material != undefined ? +detalle.estudioDetalleAvanzado?.material[0].toneladas : 0;
            } else {
                estudioDetalleExport.toneladas = detalle.estudioDetalleAvanzado?.material != undefined ? +detalle.estudioDetalleAvanzado?.material[index].toneladas : 0;
            }
            estudioExport.estudioDetalle.push(estudioDetalleExport);
            mediaCoeficiente += detalle.coeficiente;
        });

        estudioExport.toneladas = estudioExport.estudioDetalle.reduce((partialSum, a) => partialSum + a.toneladas, 0) ?? 0;
        estudioExport.coeficiente = mediaCoeficiente / estudioDetalle.length;

        estudioDetalle.forEach((detalle, index) => {
            let estudioDetalleExport: EstudioDetalleExport = {} as EstudioDetalleExport;
            estudioDetalleExport.estudioDetalleAvanzado = {} as EstudioDetalleAvanzadoExport;
            estudioDetalleExport.estudioDetalleAvanzado.dias = detalle.estudioDetalleAvanzado?.dias;
            let rendimientoMedio: number;
            let material: EstudioDetalleAvanzadoMaterial | undefined = detalle.estudioDetalleAvanzado?.material!.find(x => x.orden === detalle.orden);
            estudioDetalleExport.toneladas = +(material?.toneladas ?? 0);

            if (calculoAvanzadoId == CALCULO_AVANZADO_ID_ZAHORRA) {
                rendimientoMedio = material ? +(+material.toneladas / detalle.estudioDetalleAvanzado?.dias!).toPrecision(10) : 0;
            } else if (calculoAvanzadoId == CALCULO_AVANZADO_ID_FRESADO || calculoAvanzadoId == CALCULO_AVANZADO_ID_PLANTILLA) {
                rendimientoMedio = material ? +(+material.superficie / detalle.estudioDetalleAvanzado?.dias!).toPrecision(10) : 0;
            } else {
                rendimientoMedio = +(estudioExport.toneladas / detalle.estudioDetalleAvanzado?.dias!).toPrecision(10);
            }

            estudioDetalleExport.estudioDetalleAvanzado.rendimientoMedio = +rendimientoMedio.toFixed(2);
            estudioDetalleExport.descripcion = detalle.descripcion;
            estudioDetalleExport.CalculoAvanzadoId = detalle.unidadObra?.calculoAvanzadoId ?? 1;
            estudioDetalleExport.estudioDetalleAvanzado.material = [];
            estudioDetalleExport.unidadObraId = detalle.unidadObraId;
            estudioDetalleExport.estudioDetalleAvanzado.material?.push(material!);
            estudioDetalleExport.estudioDetalleAvanzado.dias = detalle.estudioDetalleAvanzado?.dias;
            estudioDetalleExport.riego = material!.riego;
            estudioDetalleExport.estudioDetalleAvanzado.tramo = detalle.estudioDetalleAvanzado?.tramo;
            if (calculoAvanzadoId == CALCULO_AVANZADO_ID_ZAHORRA) {
                if (+detalle.unidadMedidaId == 3) {//M3
                    estudioDetalleExport.medicion = material?.volumen!;
                } else if (+detalle.unidadMedidaId == 2) {//M2
                    estudioDetalleExport.medicion = +material?.superficie!;
                } else {
                    estudioDetalleExport.medicion = +material?.toneladas!
                }
            }
            estudioDetalleExport.estudioDetalleAvanzado.equipo = detalle.estudioDetalleAvanzado?.equipo;
            estudioDetalleExport.estudioDetalleAvanzado.traslado = detalle.estudioDetalleAvanzado?.traslado;
            estudioDetalleExport.unidadMedidaId = +detalle.unidadMedidaId;

            let equipoEuroTm: number = 0;
            let trasladoEuroTm: number = 0;

            if (calculoAvanzadoId == CALCULO_AVANZADO_ID_FRESADO || calculoAvanzadoId == CALCULO_AVANZADO_ID_PLANTILLA) {
                equipoEuroTm = +((detalle.estudioDetalleAvanzado?.equipo!.reduce((partialSum, a) =>
                    partialSum + ((+a.unidades * +a.dias) * +a.tarifa), 0) ?? 1) / detalle.medicion).toFixed(2);
                trasladoEuroTm = +((detalle.estudioDetalleAvanzado?.traslado!.reduce((partialSum, a) =>
                    partialSum + (+a.dias * +a.tarifa), 0) ?? 1) / detalle.medicion).toFixed(2);
            } else {
                equipoEuroTm = +((detalle.estudioDetalleAvanzado?.equipo!.reduce((partialSum, a) =>
                    partialSum + (+a.dias * +a.tarifa), 0) ?? 1) / estudioExport.toneladas).toFixed(2);
                trasladoEuroTm = +((detalle.estudioDetalleAvanzado?.traslado!.reduce((partialSum, a) =>
                    partialSum + (+a.dias * +a.tarifa), 0) ?? 1) / estudioExport.toneladas).toFixed(2);
            }

            if (detalle.estudioDetalleAvanzado?.equipo && detalle.estudioDetalleAvanzado?.traslado && calculoAvanzadoId == CALCULO_AVANZADO_ID_FRESADO) {

                detalle.estudioDetalleAvanzado?.equipo.forEach(equipo => {
                    let coste = +((+equipo.unidades * +equipo.dias) * +equipo.tarifa).toFixed(2)
                    let equipoExport = estudioDetalleExport.estudioDetalleAvanzado.equipo?.find(x => x.id == equipo.id)
                    if (equipoExport) {
                        equipoExport.coste = coste
                        equipoExport.costeM2 = +(coste / detalle.medicion).toFixed(2)
                    }
                });

                detalle.estudioDetalleAvanzado?.traslado.forEach(traslado => {
                    let coste = +(+traslado.dias * +traslado.tarifa).toFixed(2)
                    let trasladoExport = estudioDetalleExport.estudioDetalleAvanzado.traslado?.find(x => x.id == traslado.id)
                    if (trasladoExport) {
                        trasladoExport.coste = coste
                        trasladoExport.costeM2 = +(coste / detalle.medicion).toFixed(2)
                    }

                });
            }

            estudioDetalleExport.estudioDetalleAvanzado.equiposCosteTm = equipoEuroTm;
            estudioDetalleExport.estudioDetalleAvanzado.trasladosCosteTm = trasladoEuroTm;
            estudioDetalleExport.coeficiente = detalle.coeficiente;
            estudioDetalleExport.precioCoste = +(detalle.precioCoste).toFixed(2);
            estudioDetalleExport.precioVenta = +(detalle.precioVenta).toFixed(2);
            estudioDetalleExport.unidadDescripcion = detalle.unidadMedida.descripcion!;
            estudioDetalleExport.totalCoste = detalle.importeCoste;
            estudioDetalleExport.totalVenta = detalle.importeVenta;

            if (calculoAvanzadoId == CALCULO_AVANZADO_ID_FRESADO || calculoAvanzadoId == CALCULO_AVANZADO_ID_PLANTILLA) {
                estudioDetalleExport.precioCosteM2 = estudioDetalleExport.precioCoste;
                estudioDetalleExport.precioVentaM2 = estudioDetalleExport.precioVenta;
                estudioDetalleExport.totalCosteM2 = estudioDetalleExport.totalCoste;
                estudioDetalleExport.totalVentaM2 = estudioDetalleExport.totalVenta;
            } else {
                let equipoTrasladoEuroTm: number = equipoEuroTm + trasladoEuroTm;
                const tramoTarifa = estudioDetalleExport.estudioDetalleAvanzado?.tramo?.length != 0 ? +estudioDetalleExport.estudioDetalleAvanzado.tramo![0].tarifa : 0;
                material!.totalEurosM2 = +(+material!.riego + +(+material!.baremo + equipoTrasladoEuroTm + tramoTarifa).toFixed(2) * (+material!.espesor / 100) * +material!.densidad).toFixed(2);
                let totales = updateTotales(material!, equipoTrasladoEuroTm, tramoTarifa, +detalle.unidadMedida.id, calculoAvanzadoId);
                estudioDetalleExport.precioCosteM2 = totales.precioCosteM2;
                estudioDetalleExport.precioVentaM2 = totales.precioVentaM2;
                estudioDetalleExport.precioCosteTm = totales.precioCosteTm;
                estudioDetalleExport.precioVentaTm = totales.precioVentaTm;
                estudioDetalleExport.precioCosteM3 = totales.precioCosteM3;
                estudioDetalleExport.precioVentaM3 = totales.precioVentaM3;
                estudioDetalleExport.totalCosteTm = totales.importeCosteTm ?? 0;
                estudioDetalleExport.totalCosteM2SinRiego = totales.importeCosteM2SinRiego ?? 0;
                estudioDetalleExport.totalCosteM2 = totales.importeCosteM2 ?? 0;
                estudioDetalleExport.totalCosteM3 = totales.importeCosteM3 ?? 0;
                estudioDetalleExport.totalVentaTm = totales.importeVentaTm ?? 0;
                estudioDetalleExport.totalVentaM2 = totales.importeVentaM2 ?? 0;
                estudioDetalleExport.totalVentaM3 = totales.importeVentaM3 ?? 0;
            }

            estudioExport.estudioDetalle[index] = estudioDetalleExport;
        });
    }

    estudioExport.totalCosteTm = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + (x.totalCosteTm ?? 0), 0)).toFixed(2);
    estudioExport.totalVentaTm = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + (x.totalVentaTm ?? 0), 0)).toFixed(2);
    estudioExport.totalCosteM2SinRiego = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + (x.totalCosteM2SinRiego ?? 0), 0)).toFixed(2);
    estudioExport.totalCosteM2 = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + (x.totalCosteM2 ?? 0), 0)).toFixed(2);
    estudioExport.totalVentaM2 = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + (x.totalVentaM2 ?? 0), 0)).toFixed(2);

    estudioExport.totalRiegoPrecioCosteM2 = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + ((x.riego ?? 0) + (x.precioCosteM2 ?? 0)), 0)).toFixed(2);
    estudioExport.totalPrecioVentaM2 = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + (x.precioVentaM2 ?? 0), 0)).toFixed(2);

    if (calculoAvanzadoId == CALCULO_AVANZADO_ID_ZAHORRA) {
        estudioExport.totalCosteM3 = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + x.totalCosteM3, 0)).toFixed(2);
        estudioExport.totalVentaM3 = +(estudioExport.estudioDetalle.reduce((prev, x) => prev + x.totalVentaM3, 0)).toFixed(2);
    }

    return estudioExport;
}

const generateExcelTotalizar = (estudioDetalle: EstudioDetalle[], totalToneladas: number): EstudioDetalleExport[] => {
    let estudiosDetalleExport: EstudioDetalleExport[] = [];

    estudioDetalle[0].estudioDetalleAvanzado?.material?.sort((a, b) => a.totalizarGrupo! - b.totalizarGrupo!).forEach((material, index) => {
        let estudioDetalleExport: EstudioDetalleExport = {} as EstudioDetalleExport;
        estudioDetalleExport.estudioDetalleAvanzado = {} as EstudioDetalleAvanzadoExport;
        estudioDetalleExport.totalesGrupo = {} as EstudioDetalleTotalesGrupo;
        let detalle: EstudioDetalle = estudioDetalle[0];
        estudioDetalleExport.estudioDetalleAvanzado.dias = estudioDetalle[0].estudioDetalleAvanzado?.dias;
        let rendimientoMedio: number = +(totalToneladas / estudioDetalle[0].estudioDetalleAvanzado?.dias!).toPrecision(10);

        estudioDetalleExport.toneladas = +(material?.toneladas ?? 0);
        estudioDetalleExport.estudioDetalleAvanzado.rendimientoMedio = +rendimientoMedio.toFixed(2);
        estudioDetalleExport.descripcion = estudioDetalle.find(x => x.totalizarGrupo != undefined && x.totalizarGrupo == material.totalizarGrupo) ? estudioDetalle.find(x => x.totalizarGrupo == material.totalizarGrupo)?.descripcion : estudioDetalle.find(x => x.orden == material.orden)?.descripcion;
        estudioDetalleExport.CalculoAvanzadoId = detalle.unidadObra?.calculoAvanzadoId ?? 1;
        estudioDetalleExport.estudioDetalleAvanzado.material = [];
        estudioDetalleExport.unidadObraId = detalle.unidadObraId;
        estudioDetalleExport.estudioDetalleAvanzado.material?.push(material);
        estudioDetalleExport.estudioDetalleAvanzado.dias = detalle.estudioDetalleAvanzado?.dias;
        estudioDetalleExport.riego = material.riego;
        estudioDetalleExport.estudioDetalleAvanzado.tramo = detalle.estudioDetalleAvanzado?.tramo;
        estudioDetalleExport.medicion = +material.superficie; // M2
        estudioDetalleExport.estudioDetalleAvanzado.equipo = detalle.estudioDetalleAvanzado?.equipo;
        estudioDetalleExport.estudioDetalleAvanzado.traslado = detalle.estudioDetalleAvanzado?.traslado;

        let equipoEuroTm: number = +((detalle.estudioDetalleAvanzado?.equipo!.reduce((partialSum, a) =>
            partialSum + (+a.dias * +a.tarifa), 0) ?? 1) / totalToneladas).toFixed(2);
        let trasladoEuroTm: number = +((detalle.estudioDetalleAvanzado?.traslado!.reduce((partialSum, a) =>
            partialSum + (+a.dias * +a.tarifa), 0) ?? 1) / totalToneladas).toFixed(2);

        estudioDetalleExport.estudioDetalleAvanzado.equiposCosteTm = equipoEuroTm;
        estudioDetalleExport.estudioDetalleAvanzado.trasladosCosteTm = trasladoEuroTm;
        estudioDetalleExport.coeficiente = material.coeficiente;
        estudioDetalleExport.unidadDescripcion = detalle.unidadMedida.descripcion!;
        let equipoTrasladoEuroTm: number = equipoEuroTm + trasladoEuroTm;
        const tramoTarifa = estudioDetalleExport.estudioDetalleAvanzado?.tramo?.length != 0 ? +estudioDetalleExport.estudioDetalleAvanzado.tramo![0].tarifa : 0;
        material.totalEurosM2 = +(+material.riego + +(+material.baremo + equipoTrasladoEuroTm + tramoTarifa).toFixed(2) * (+material.espesor / 100) * +material.densidad).toFixed(2);
        let totales = updateTotales(material, equipoTrasladoEuroTm, tramoTarifa, +detalle.unidadMedida.id, estudioDetalleExport.CalculoAvanzadoId);
        estudioDetalleExport.precioCosteM2 = totales.precioCosteM2;
        estudioDetalleExport.precioVentaM2 = totales.precioVentaM2;
        estudioDetalleExport.precioCosteTm = totales.precioCosteTm;
        estudioDetalleExport.precioVentaTm = totales.precioVentaTm;
        estudioDetalleExport.precioCosteM3 = totales.precioCosteM3;
        estudioDetalleExport.precioVentaM3 = totales.precioVentaM3;
        estudioDetalleExport.totalCosteTm = totales.importeCosteTm ?? 0;
        estudioDetalleExport.totalCosteM2SinRiego = totales.importeCosteM2SinRiego ?? 0;
        estudioDetalleExport.totalCosteM2 = totales.importeCosteM2 ?? 0;
        estudioDetalleExport.totalCosteM3 = totales.importeCosteM3 ?? 0;
        estudioDetalleExport.totalVentaTm = totales.importeVentaTm ?? 0;
        estudioDetalleExport.totalVentaM2 = totales.importeVentaM2 ?? 0;
        estudioDetalleExport.totalVentaM3 = totales.importeVentaM3 ?? 0;
        estudioDetalleExport.unidadMedidaId = +detalle.unidadMedidaId;
        estudioDetalleExport.totalizarGrupo = material?.totalizarGrupo ?? undefined;

        totalesGrupo(material, estudioDetalleExport, estudioDetalle, totales, estudiosDetalleExport);

        estudiosDetalleExport.push(estudioDetalleExport);
    });

    return estudiosDetalleExport;
}
function totalesGrupo(material: EstudioDetalleAvanzadoMaterial, estudioDetalleExport: EstudioDetalleExport, estudioDetalle: EstudioDetalle[], totales: MaterialCalculo, estudiosDetalleExport: EstudioDetalleExport[]) {
    if (material.totalizarGrupo == null) {
        estudioDetalleExport.totalesGrupo!.totalCosteSinRiego = +(totales.precioCosteM2! * +material.superficie).toFixed(2);
        estudioDetalleExport.totalesGrupo!.totalCoste = estudioDetalle.find(x => x.orden == material.orden)?.importeCoste;
        estudioDetalleExport.totalesGrupo!.totalOferta = estudioDetalle.find(x => x.orden == material.orden)?.importeVenta;
        estudioDetalleExport.totalesGrupo!.sumaTNGrupo = +material.toneladas
    } else {

        let materiales = estudioDetalle[0].estudioDetalleAvanzado?.material?.filter(x => x.totalizarGrupo == material.totalizarGrupo);

        if (material === materiales![materiales?.length! - 1]) {

            let estudioDetalles = estudioDetalle.filter(x => x.totalizarGrupo == material.totalizarGrupo);
            let sumaTNGrupo: number = +(materiales!.reduce((partialSum, a) => partialSum + +a.toneladas, 0)).toFixed(2);

            estudioDetalleExport.totalesGrupo!.sumaTNGrupo = +sumaTNGrupo.toFixed(2);
            estudioDetalleExport.totalesGrupo!.sumaVentas = +estudioDetalles.reduce((partialSum, a) => partialSum + (a.precioVenta), 0).toFixed(2);
            
            let estudiosGrupo = estudiosDetalleExport.filter(x => x.totalizarGrupo == material.totalizarGrupo)
            estudioDetalleExport.totalesGrupo!.sumaCostes = +(+estudiosGrupo.reduce((partialSum, a) => partialSum + (a.precioCosteM2!), 0) + totales.precioCosteM2!).toFixed(2);
            estudioDetalleExport.totalesGrupo!.totalCosteSinRiego = estudiosGrupo.reduce((partialSum, a) => partialSum + a.totalCosteM2SinRiego!, 0) + totales.importeCosteM2SinRiego!;
            
            estudioDetalleExport.totalesGrupo!.totalCoste = estudioDetalle.find(x => x.totalizarGrupo == material.totalizarGrupo)?.importeCoste;
            estudioDetalleExport.totalesGrupo!.totalOferta = estudioDetalle.find(x => x.totalizarGrupo == material.totalizarGrupo)?.importeVenta;
        } else {
            estudioDetalleExport.totalesGrupo!.sumaTNGrupo = 0;
        }
    }
}
