import { BaseEntity } from "@pavabits/components";
import { formatNumber } from "../../../../utils/NumberUtil";



export enum TipoAccionComercial {
	LICITACION = "Licitación",
	OFERTA = "Oferta",
	AJUSTE = "Ajuste",
}

export interface AccionComercialDto {
	accionComercialId:string
	codigo: number
	delegacionDescripcion: string
	obraDescripcion: string
	promotorDescripcion: string
	fecha: string
	oportunidadId: string
	presupuesto: number
	toneladas: number
	accionComercialTipo: string
	porcentajeAsfalto: number
	fechaAdjudicacion: string
	porcentajeBaja: number
	estadoAccionDescripcion: string;
	ajusteToneladas?:number
	motivo?:string
	tipoOferta?: string
	addOferta?: boolean
}

export default class AccionComercial extends BaseEntity {
	constructor(
		readonly _accionComercialId:string,
		readonly _codigoAccion: number,
		readonly _delegacion: string,
		readonly _obra: string,
		readonly _promotor: string,
		readonly _fecha: Date,
		readonly _oportunidadId: string,
		readonly _presupuesto: number,
		readonly _toneladas: number,
		readonly _accionComercialTipo: string,
		readonly _porcentajeAsfalto: number,
		readonly _fechaAdjudicacion: Date,
		readonly _porcentajeBaja: number,
		readonly _estadoAccionDescripcion: string,
		readonly _ajusteToneladas?: number,
		readonly _motivo?:string,
		readonly _tipoOferta?:string,
		readonly _addOferta?:boolean
	) {
		super(_codigoAccion.toString(), "");
	}

	get codigoAccion(){
		return this._codigoAccion;
	}

	get delegacion(){
		return this._delegacion;
	}

	get obra(){
		return this._obra;
	}

	get promotor(){
		return this._promotor;
	}

	get fecha(){
		return this._fecha;
	}

	get oportunidadId(){
		return this._oportunidadId;
	}

	get presupuesto(){
		return this._presupuesto;
	}
	
	get toneladas(){
		return this._toneladas;
	}

	get accionComercialTipo(){
		return this._accionComercialTipo;
	}

	get porcentajeAsfalto(){
		return this._porcentajeAsfalto;
	}

	get fechaAdjudicacion(){
		return this._fechaAdjudicacion;
	}

	get porcentajeBaja(){
		return this._porcentajeBaja;
	}

	get accionComercialId(){
		return this._accionComercialId;
	}

	get ajusteToneladas(){
		return this._ajusteToneladas;
	}

	get motivo(){
		return this._motivo;
	}
	get tipoOferta(){
		return this._tipoOferta;
	}
	get addOferta(){
		return this._addOferta;
	}

	get estadoAccionDescripcion() {
		return this._estadoAccionDescripcion;
	}

	get presupuestoFormatted(){
		return formatNumber(this._presupuesto);
	}
}