import { BaseEntity } from "@pavabits/components";
import RowMapper from "../model/RowMapper";
import React, { ReactNode, useState } from "react";

export interface TableRowProps<TObject extends BaseEntity> {
  item: TObject
  rowMapper: RowMapper<TObject>
  empty?: boolean
  className?: string
  expandedColSpan?: number
  RowExpansion?: (id: string) => ReactNode
  allExpanded?: boolean
  addOferta?: () => void
}

const TableRow = <TObject extends BaseEntity>(props: TableRowProps<TObject>) => {
  const { expandedColSpan, className, rowMapper, RowExpansion, item, allExpanded, addOferta } = props;

  const RowRender = rowMapper.rowMapper(item);
  
  const [expanded, setExpanded] = useState(false);

  const trClass = ["tablerow", className + "__tr"].join(" ").trimEnd();
  
  function onRowExpansion() {
    setExpanded(!expanded);
  }

  return (
    <RowRender
      expandedColSpan={expandedColSpan}
      // cellClassName={className}
      // rowClassName={trClass}
      actionClassName={className + "__row-button"}
      expandedSection={RowExpansion}
      expanded={expanded}
      onExpand={onRowExpansion}
      allExpanded={allExpanded}
    />
  )
}

export default TableRow;