import React from "react";
import Oportunidad, { OportunidadFilter } from "../domain/model/Oportunidad";
import { BaseFilter, SortingField } from "../../../domain/BaseFilter";
import BaseResponse from "../../../domain/BaseResponse";
import { ColumnTraits } from "../../../hooks/useColumnasGrid";
import { FilterHandler, SearchFilterHandler, SortingHandler } from "../hooks/useFilters/FilterTypes";
import { FilterTag } from "./FilterFields.Context";

interface ColumnConfig {
	columns: { [key: string]: ColumnTraits }
}

const defaultColumns = {
	columns: {}
}

export const OportunidadesColumnsContext = React.createContext<ColumnConfig>(defaultColumns);

interface OportunidadesData {
	columns: { [key: string]: ColumnTraits },
	data: BaseResponse<Oportunidad[]>,
	downloadExcel?:()=>void,
	downloadExcelDetallado?:()=>void,
	sumaToneladas:number
}

const defaulOportunidades = {} as OportunidadesData;

export const OportunidadesDataContext = React.createContext<OportunidadesData>(defaulOportunidades);

interface LicitacionesColumnConfig {
	columns: { [key: string]: ColumnTraits }
}

const defaultLicitacionesColumns = {
	columns: {}
}

export const LicitacionesColumnsContext = React.createContext<LicitacionesColumnConfig>(defaultLicitacionesColumns);

// interface FilterContext {
// 	filter: BaseFilter<OportunidadFilter>,
// }

// const defaultFilter = {
// 	filter: {} as BaseFilter<OportunidadFilter>,
// }

interface SearchHandlerContext {
	onSearchField: SearchFilterHandler<OportunidadFilter>;
}
const searchHandler: SearchHandlerContext = {} as SearchHandlerContext;

export const OportunidadesSearchHandler = React.createContext<SearchHandlerContext>(searchHandler);
//export const OportunidadesFilterContext = React.createContext<FilterContext>(defaultFilter);

type FiltersHandlers = FilterHandler<OportunidadFilter>;

const defaultFilterHandlers = {} as FiltersHandlers

export const OportunidadesFilterHandlersContext = React.createContext<FiltersHandlers>(defaultFilterHandlers);

type SortingHandlers = SortingHandler<OportunidadFilter>;

const defaultSortinHandlers: SortingHandlers = {
	onPageBackwards: () => {},
	onPageSelect: () => {},
	onSetMaxCount: () => () => {},
	onSort: () => () => {}
}

export const OportunidadesSortingHandlersContext = React.createContext<SortingHandlers>(defaultSortinHandlers);
interface SortingContext {
	sorting: SortingField[],
	maxCount: number,
}

const defaultSorting = {
	sorting: [],
	maxCount: 10
}

export const OportunidadesSortingContext = React.createContext<SortingContext>(defaultSorting);

interface Tags {
	tags: FilterTag[]
}

export const OportunidadesTags = React.createContext<Tags>({ tags: [] });

interface TagsHandler {
	onFilter: (tag: FilterTag) => void
}

export const OportunidadesTagHandler = React.createContext<TagsHandler>({} as TagsHandler);

interface SortingAndPagination extends SortingHandler<OportunidadFilter> {
	maxResultCount: number
	sorting: SortingField[]
}

const defaultPagination = {} as SortingAndPagination;
export const OportunidadesPagination = React.createContext<SortingAndPagination>(defaultPagination);

