import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import OportunidadesRepository from "../../domain/OportunidadesRepository";
import Adjudicacion from "../../domain/model/Adjudicacion";
import Contratista from "../../domain/model/Contratista";
import Oportunidad, { OportunidadDto, OportunidadFilter } from "../../domain/model/Oportunidad";
import Promotor from "../../domain/model/Promotor";
import Provincia from "../../domain/model/Provincia";
import OportunidadesRequestListByFilter from "../ws/Oportunidades.RequestListByFilter";
import OportunidadesRequestDownloadExcel from "../ws/OportunidadesRequestDownloadExcel";
import OportunidadesRequestDownloadExcelDetallado from "../ws/OportunidadesRequestDownloadExcelDetallado";
import OportunidadesRequestGetSumaToneladas from "../ws/OportunidadesSumToneladas";

export default class OportunidadesApiRepository extends ApiRepositoryBase<
	OportunidadDto,
	Oportunidad
> implements OportunidadesRepository {

	async fetchOportunidadesByFilter(data: BaseFilter<OportunidadFilter>): Promise<BaseResponse<Oportunidad[]>> {
		const res = await new OportunidadesRequestListByFilter(data, this.authToken).executeAsPromise();
		return (
			{
				...res,
				items: Oportunidad.toArray(res.items)
			}
		);
		// return new Promise((resolve, reject) => {
		// 	new OportunidadesRequestListByFilter(data, this.authToken)
		// 		.request()
		// 		.then((response) => {
		// 			if (response.success) {
		// 				resolve(this.transformDtoIntoModel(response.data));
		// 			} else {
		// 				reject(`Error ${response.errorCode}: ${response.errorMessage}`);
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			reject(error);
		// 		})
		// })
	}

	fetchOportunidadesSumaToneladas(data: BaseFilter<OportunidadFilter>): Promise<number> {
		return new Promise((resolve, reject) => {
			new OportunidadesRequestGetSumaToneladas(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(response.data);
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	fetchOportunidadesDownloadExcel(data: BaseFilter<OportunidadFilter>): Promise<string> {
		return new Promise((resolve, reject) => {
			new OportunidadesRequestDownloadExcel(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(response.data);
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}
	fetchOportunidadesDownloadExcelDetallado(data: BaseFilter<OportunidadFilter>): Promise<string> {
		return new Promise((resolve, reject) => {
			new OportunidadesRequestDownloadExcelDetallado(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(response.data);
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	buildModelFromItems(item: OportunidadDto): Oportunidad {
		return new Oportunidad(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<OportunidadDto>): BaseResponse<Oportunidad> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}

}
