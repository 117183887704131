import TableBodyMapper from "./model/RowMapper";
import { ReactNode } from "react";
import "./TableGridView.scss";
import { BaseEntity } from "@pavabits/components";
import TableRow from "./components/TableRow";
import { formatNumber } from "../../utils/NumberUtil";
import LicitacionesRow from "../../modules/Oportunidades/views/objects/Licitaciones.Row";

interface TableGridProps<TObject extends BaseEntity> {
	columns: { [key: string]: any }
	sumaToneladas?:number
	data: Array<TObject>
	expandedColSpan?: number,
	className?: string
	tableBodyMapper?: TableBodyMapper<TObject>
	RowExpansion?: (id: string) => ReactNode
	SortingComponent?: (key: string) => ReactNode
	HeaderAction?: ReactNode
	allExpanded?: boolean
}

const TableGridView = <TObject extends BaseEntity>(props: TableGridProps<TObject>) => {

	const {
		className,
		sumaToneladas,
		columns,
		data,
		expandedColSpan,
		tableBodyMapper,
		SortingComponent,
		RowExpansion,
		HeaderAction,
		allExpanded
	} = props;

	const theadClass = ["table__header", className + "__thead"].join(" ").trimEnd();
	const tfootClass = ["table__footer", className + "__foot"].join(" ").trimEnd();
	const trClass = ["tablerow", className + "__thead-tr"].join(" ").trimEnd();
	const thClass = ["table__header__th", className + "__th"].join(" ").trimEnd();
	const tbodyClass = ["table__body", className + "__tbody"].join(" ").trimEnd();

	return (
		// <table key={`table-table-${data}`} className={["table", className].join(" ").trimEnd()}>
		<table key={`table-table-${data}`}>
			<thead className={(tableBodyMapper != undefined && tableBodyMapper instanceof LicitacionesRow) ? "expanded" : ""} key={`table-row-${data}`}>
			{/* <thead  key={`table-row-${data}`} className={theadClass}> */}
				<tr key={data.join('-')}>
				{/* <tr  key={data.join('-')}className={trClass}> */}
					{RowExpansion !== undefined && <th></th>}
					{/* {RowExpansion !== undefined && <th className={thClass}></th>} */}
					{
						Object.keys(columns).map((key, index) => {
							return (
							<th key={index} >
									<div className="header-cell">
										{columns[key].columnName }
										{(columns[key].sortable && SortingComponent) && SortingComponent(key)}
									</div>
									{
										key==="toneladas"?
											<div className="toneladas">{ formatNumber(sumaToneladas!==undefined?sumaToneladas:0)}</div>
											:
											''
									}
								</th>)
						})
					}
					{HeaderAction !== undefined && <th>{HeaderAction}</th>}
					{/* {HeaderAction !== undefined && <th className={thClass}>{HeaderAction}</th>} */}
				</tr>
			</thead>
			<tbody key={"table-body"}>
			{/* <tbody key={"table-body"} className={tbodyClass}> */}
				{data.map((item) => {
					return (
						<TableRow
							key={`table-row-${item.id}`}
							item={item}
							// className={className+"__td"}
							rowMapper={tableBodyMapper!}
							expandedColSpan={expandedColSpan}
							RowExpansion={RowExpansion}
							allExpanded={allExpanded}
						/>
					)
				})}
			</tbody>
			<tfoot className={tfootClass}>
				<tr>

				</tr>
			</tfoot>
		</table>
	)

}

export default TableGridView;