import { PropsWithChildren, useContext } from "react";
import { useHistory } from "react-router-dom";
import { HistoryHandler } from "../../../context/History.Context";
import { Routes } from "../../../router/routes/Routes";
import LabelButton from "../../ui/atoms/Buttons/LabelButton";
import BottomButtons from "../../ui/molecules/Bottom-buttons/BottomButtons";
import TableScreen from "../TableScreen/Table.Screen";

interface Props {
  title?: string;
  formId: string;
  className?: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  goBackVisible?: boolean;
  saveDisabled?: boolean;
  backUrl?: boolean;
  readOnly?: boolean;
  idTestBotonGuardar?: string;
  backCancelar?: boolean
}

const FormTemplate: React.FC<Props> = ({
  className,
  formId,
  title,
  children,
  onSubmit,
  goBackVisible,
  saveDisabled,
  backUrl,
  readOnly,
  idTestBotonGuardar,
  backCancelar
}: PropsWithChildren<Props>) => {
  const history = useHistory();
  const { goToRoute } = useContext(HistoryHandler);

  const back = () => {
    if (backUrl) {
      return goToRoute(Routes.EQUIPOS + "?backUrl=1");
    } else {
      return goToRoute(Routes.EQUIPOS);
    }
  };

  const backMinus = () => {
    return history.go(-1);
  }

  const backCancel = () => {
    return goToRoute(Routes.OPORTUNIDADES)
  }


  return (
    <div>
      <TableScreen title={title ?? ""}>
        <form id={formId} className={className} onSubmit={onSubmit}>
          <fieldset disabled={readOnly}>{children}</fieldset>
        </form>
      </TableScreen>
      <BottomButtons>
        <LabelButton
          label="Guardar"
          idTest={idTestBotonGuardar && idTestBotonGuardar !== undefined ? idTestBotonGuardar : 'Guardar'}
          type="submit"
          formId={formId}
          disabled={readOnly || saveDisabled}
        />

        {(goBackVisible == undefined || goBackVisible) && (
          <LabelButton
            label="Atrás"
            type="button"
            onClick={
              backUrl != undefined || backUrl != null ? back : backCancelar ? backCancel : backMinus
            }
            idTest="Atras"
          />
        )}
      </BottomButtons>
    </div>
  );
};
export default FormTemplate;
