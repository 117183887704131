import TablePrintableObject from "../../../../components/Table/types/TablePrintableObject";
import { BiasedType } from "../../../../domain/BaseFilter";
import { formatDate2String } from "../../../../utils/DateUtil";
import { formatNumber } from "../../../../utils/NumberUtil";
import Adjudicacion from "./Adjudicacion";

type FilterExcludedProperties = 
| "id"
| "text"
// | "codigo"
//| "oportunidadId"
| "adjudicatario"

type BiasedOportunidad = BiasedType<Oportunidad, FilterExcludedProperties>;

//export type OportunidadFilter = FilterFromType<BiasedOportunidad>;

export type OportunidadFilter = {
	fechaRegistroDesde: Date,
	fechaRegistroHasta : Date,
	fechaAdjudicacionDesde: Date,
	fechaAdjudicacionHasta : Date,
	codigo: string, 
	licitacionCodigo?: string,
	ofertaCodigo: string
	obraDescripcion: string, 
	promotorIds : number[],
	provinciaIds: number[], 
	estadoOportunidadIds: number[],
	tipoOportunidadId: number,
	delegacionIds: number[], 
	conContratista: boolean,
	contratistaIds: number[]
	asfalteraIds: string[],
	licitamos: string[],
	minToneladas: number,
	maxToneladas: number
}

interface OportunidadEntity {
	
	id: string,
	codigo: string,
	//oportunidadId: string,
	fechaRegistro: Date,
	promotorId: number,
	promotorDescripcion: string
	toneladas: number,
	provinciaId: number,
	provinciaDescripcion: string
	municipioDescripcion: string
	contratistaDescripcion: string,
	asfaltera: string,
	obraDescripcion: string,
	licitamos:string,
	delegacion: string,
	licitacionCodigo: string,
	ofertaCodigo: number,
	adjudicaciones: Array<Adjudicacion>,
	estadoOportunidadDescripcion: string,
	estadoOportunidadId: number,
	tipoOportunidadDescripcion: string,
	tipoOportunidadId: number
}

export default class Oportunidad extends TablePrintableObject {

	id: string;
	codigo: string;
	//oportunidadId: string;
	fechaRegistro: Date;
	promotorId: number;
	promotorDescripcion: string;
	toneladas: number;
	provinciaId: number;
	provinciaDescripcion: string;
	municipioDescripcion: string;
	contratistaDescripcion: string;
	asfaltera: string;
	obraDescripcion: string;
	licitamos:string;
	delegacion: string;
	licitacionCodigo: string;
	ofertaCodigo: number;
	adjudicaciones: Array<Adjudicacion>;
	estadoOportunidadId: number;
	estadoOportunidadDescripcion: string;
	tipoOportunidadId: number;
	tipoOportunidadDescripcion: string;
	controllerName: string;


	constructor(
		entity: OportunidadEntity

	) {
		super(entity.id, entity.obraDescripcion);
		this.id = entity.id;
		this.codigo = entity.codigo;
		//this.oportunidadId = entity.oportunidadId;
		this.fechaRegistro = entity.fechaRegistro;
		this.promotorId = entity.promotorId;
		this.promotorDescripcion = entity.promotorDescripcion;
		this.toneladas = entity.toneladas;
		this.provinciaId = entity.provinciaId;
		this.provinciaDescripcion = entity.provinciaDescripcion;
		this.municipioDescripcion = entity.municipioDescripcion;
		this.contratistaDescripcion = entity.contratistaDescripcion;
		this.asfaltera = entity.asfaltera;
		this.obraDescripcion = entity.obraDescripcion;
		this.licitamos = entity.licitamos;
		this.delegacion = entity.delegacion;
		this.licitacionCodigo = entity.licitacionCodigo;
		this.ofertaCodigo = entity.ofertaCodigo;
		this.adjudicaciones = entity.adjudicaciones;
		this.estadoOportunidadDescripcion = entity.estadoOportunidadDescripcion;
		this.estadoOportunidadId = entity.estadoOportunidadId;
		this.tipoOportunidadDescripcion = entity.tipoOportunidadDescripcion;
		this.tipoOportunidadId = entity.tipoOportunidadId
		this.controllerName="oportunidades"
	}

	override toMap = () => {
		return new Map<keyof Oportunidad, string>(
			[
				["codigo", this.codigo],
				["fechaRegistro", this.fechaRegistro !== undefined ? formatDate2String(this.fechaRegistro) : ""],
				["promotorDescripcion", this.promotorDescripcion],
				["toneladas", formatNumber(+this.toneladas ?? 0)],
				["provinciaDescripcion", this.provinciaDescripcion],
				["municipioDescripcion", this.municipioDescripcion],
				["contratistaDescripcion", this.contratistaDescripcion],
				["obraDescripcion", this.obraDescripcion],
				["asfaltera", this.asfaltera],
				["licitamos", this.licitamos],
				["estadoOportunidadDescripcion", this.estadoOportunidadDescripcion],
				["tipoOportunidadDescripcion", this.tipoOportunidadDescripcion],
			]
		);
	};

	public static toArray(entities: OportunidadEntity[]): Oportunidad[] {
		return entities?.map(entity => { return new Oportunidad(entity) });
	}

	// get codigo() {
	// 	return this._codigo;
	// }

	// get oportunidadId() {
	// 	return this._oportunidadId;
	// }

	// get fechaRegistro() {
	// 	return this._fechaRegistro;
	// }

	// get promotor() {
	// 	return this._promotor;
	// }

	// get toneladas() {
	// 	return this._toneladas;
	// }

	// get provincia() {
	// 	return this._provincia;
	// }

	// get contratista() {
	// 	return this._contratista;
	// }

	// get adjudicaciones() {
	// 	return this._adjudicaciones;
	// }

	// get asfaltero(){
	// 	return this._asfaltero;
	// }

	// get obraDescripcion(){
	// 	return this._obraDescripcion;
	// }

	// get licitamos(){
	// 	return this._licitamos;
	// }

	// get toneladasFormatted(){
	// 	return formatNumber(this._toneladas);
	// }

}

export interface OportunidadDto extends Oportunidad { };
