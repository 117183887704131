import { Icon } from "@pavabits/components";
import { ReactNode, useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import "./FilterModal.scss";
import IconButton from "../Buttons/IconButton";
import IconButtonFontAwesome from "../Buttons/IconButtonFontAwesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

interface Props {
	filters?: ReactNode;
	titleShow?: string;
	modalTitle?: string;
	modalSubtitle?: string;
	downloadExcel?: any;
	downloadExcelDetallado?: any;
	label?: string;
	oportunidades?: boolean
}

const FilterModal: React.FC<Props> = ({
	filters,
	titleShow,
	modalTitle,
	modalSubtitle,
	downloadExcel,
	downloadExcelDetallado,
	label,
	oportunidades
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [openDownload, setOpenDownload] = useState<boolean>(false);

	const abrirDescarga = () => {
		setOpenDownload(true)
	}

	return (
		<>
			<div className="filters-space-container">
				<button
					data-cy={titleShow + "Boton"}
					className="btn-primary"
					onClick={() => setOpen(true)}
				>
					<Icon icon="filter_alt" />
					{titleShow}
				</button>


				{downloadExcel && !oportunidades && (
					<button className="btn-primary" onClick={downloadExcel}>
						<IconButtonFontAwesome icon={faFileExcel} />
						<p>Descargar Datos</p>
					</button>
				)}


				{downloadExcel && oportunidades && (<>

					<button className="btn-primary" onClick={abrirDescarga}>
						<IconButtonFontAwesome icon={faFileExcel} />
						<p>Descarga</p>
					</button>

					<Modal
						show={openDownload}
						onHide={() => setOpenDownload(false)}
					>
						<ModalHeader>
							<ModalTitle data-cy={label}>{"Descargar datos"}</ModalTitle>
							<IconButton icon="close" onClick={() => setOpenDownload(false)} label="Close" />
						</ModalHeader>

						<ModalBody>
							<div className="add-edit-view-container">
								<div className="row-of-two">

									<button className="btn-primary" onClick={downloadExcel}>
										<IconButtonFontAwesome icon={faFileExcel} />
										<p>Descargar Datos</p>
									</button>

									<button className="btn-primary" onClick={downloadExcelDetallado}>
										<IconButtonFontAwesome icon={faFileExcel} />
										<p>Descargar Datos Detallados</p>
									</button>
								</div>
							</div>
						</ModalBody>
					</Modal>

				</>
				)}

			</div>
			<Modal
				show={open}
				onHide={() => setOpen(false)}
			>
				<ModalHeader>
					<ModalTitle data-cy={label}>{modalTitle}</ModalTitle>
					<IconButton icon="close" onClick={() => setOpen(false)} label="Close" />
				</ModalHeader>
				{modalSubtitle && (
					<ModalTitle className="modal-title--subtitle">
						{modalSubtitle}
					</ModalTitle>
				)}
				<ModalBody>
					<div className="double-col-grid">{filters}</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default FilterModal;
