import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { COD_DELEGACIONES_CON_TODAS, PAVASAL_CLIENTEID, PAVASAL_CONTRATISTAID, USER_ROLES } from "../../../../assets/constants/Constantes";
import FormTemplate from "../../../../components/common/FormTemplate/FormTemplate";
import Dropdown, { DropdownProps } from "../../../../components/ui/atoms/Dropdown/Dropdown";
import FormInput from "../../../../components/ui/atoms/Input/FormInput/FormInput";
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from "../../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field";
import FormField from "../../../../components/ui/molecules/Form-field/FormField";
import AdjudicacionRepository from "../../../../domain/AdjudicacionRepository";
import DelegacionesRepository from "../../../../domain/DelegacionesRepository";
import { DelegacionDto } from "../../../../domain/model/Delegacion";
import AdjudicacionApiRepository from "../../../../infraestructure/api/Adjudicacion.ApiRepository";
import CodigoPostalApiRepository from "../../../../infraestructure/api/CodigoPostal.ApiRepository";
import DelegacionesApiRepository from "../../../../infraestructure/api/Delegaciones.ApiRepository";
import { formatDate2String } from "../../../../utils/DateUtil";
import Cliente from "../../../Clientes/domain/model/Cliente";
import { AuthContext } from "../../../Login/AuthContextProvider";
import ClienteDetalleRepository from "../../../Oportunidades/domain/ClienteDetalleRepository";
import ClienteRepository from "../../../Oportunidades/domain/ClienteRepository";
import ContratistaRepository from "../../../Oportunidades/domain/ContratistaRepository";
import Adjudicacion from "../../../Oportunidades/domain/model/Adjudicacion";
import Licitacion from "../../../Oportunidades/domain/model/Licitacion";
import ClienteApiRepository from "../../../Oportunidades/infraestructure/api/Cliente.ApiRepository";
import ClienteDetalleApiRepository from "../../../Oportunidades/infraestructure/api/ClienteDetalle.ApiRepository";
import ContratistaApiRepository from "../../../Oportunidades/infraestructure/api/Contratista.ApiRepository";
import ProvinciaApiRepository from "../../../Oportunidades/infraestructure/api/ProvinciaApiRepository";
import TipoOfertaRepository from "../../domain/TipoOfertaRepository";
import { EstadoOfertaEnum, TipoOfertaEnum } from "../../domain/model/Ofertas";
import TipoOferta from "../../domain/model/TipoOferta";
import useOfertasForm, { OfertasFormDto } from "../../hooks/useOfertasForm";
import TipoOfertaApiRepository from "../../infraestructure/api/TipoOferta.ApiRepository";
import CustomTextInput from "../../../../components/CustomTextInput";
import Comercial, { ComercialDto, ComercialDtoFilter } from "../../domain/model/Comercial";
import ComercialRepository from "../../domain/ComercialRepository";
import ComercialApiRepository from "../../infraestructure/api/Comercial.ApiRepository";

interface OfertasFormProps {
	licitacion?: Partial<Licitacion>
	onSubmit: (data: OfertasFormDto) => void
	clienteLicitacion?: Cliente
	tipoOferta?: TipoOferta
}

const OfertasFormTemplate: React.FC<OfertasFormProps> = (
	{
		licitacion,
		onSubmit,
		clienteLicitacion,
		tipoOferta
	}: OfertasFormProps
) => {


	const { onField, onSubmitForm } = useOfertasForm({
		initialValues: {
			...licitacion,
			codigo: undefined,
			delegacionId: undefined,
			promotorId: licitacion?.promotorId,
			clienteId: clienteLicitacion?.id ?? undefined,
			clienteDescripcion: clienteLicitacion?.descripcion ?? undefined,
			fechaOferta: formatDate2String(new Date()),
			licitacionCodigo: licitacion?.codigo,
			tipoOfertaId: licitacion?.codigo !== undefined ? tipoOferta ? +tipoOferta.id : TipoOfertaEnum.Licitacion : TipoOfertaEnum.Ejecucion,
			comercialId: 100,
			iva: clienteLicitacion?.iva ?? 0
		}, handleOnSubmit: onSubmit
	});
	const [optionsTipoOferta, setOptionsTipoOferta] = useState<TipoOferta[]>([]);
	const [optionsComerciales, setOptionsComerciales] = useState<Comercial[]>([]);
	const [selectedComercial, setSelectedComercial] = useState<ComercialDto>({} as ComercialDto);
	const [optionsDelegaciones, setOptionsDelegaciones] = useState<DelegacionDto[]>([]);
	const [selectedDelegacion, setSelectedDelegacion] = useState<DelegacionDto>({} as DelegacionDto);
	const [refreshProvincia, setRefreshProvincia] = useState<boolean>(true);
	const { getToken, getDelegacion, hasRole, getEmail } = useContext(AuthContext);
	const onSearchContratista = useCallback((search: string, ute?: string) => {
		const repo: ContratistaRepository = new ContratistaApiRepository(getToken());
		return repo.searchContratista({ filter: { descripcion: search, asfaltero: "", ute: "" } });
	}, []);

	const onSearchCliente = useCallback((search: string) => {
		const repo: ClienteRepository = new ClienteApiRepository(getToken());
		return repo.searchCliente({ filter: { descripcion: search } });
	}, []);
	const onSearchCodigoPostal = useCallback((search: string) => {
		const repo: CodigoPostalApiRepository = new CodigoPostalApiRepository(getToken());
		return repo.fetchCodigoPostal({ filter: { nombreMunicipio: search } });
	}, []);

	const onSearchProvincias = useCallback((search: string) => {
		const repo: ProvinciaApiRepository = new ProvinciaApiRepository(getToken());
		return repo.searchProvincias({ filter: { descripcion: search } });
	}, []);

	const getProvinciaByCPCode = useCallback((id: string) => {
		const repo: CodigoPostalApiRepository = new CodigoPostalApiRepository(getToken());
		return repo.getCodigoPostalById(id);
	}, []);

	const [adjudicacion, setAdjudicacion] = useState<Adjudicacion[]>([]);
	const ofertaInternaSelectable = () => adjudicacion && adjudicacion.length > 0 && adjudicacion.filter(p => p.contratistaId == PAVASAL_CONTRATISTAID).length > 0;
	const ofertaComercialSelectable = () => optionsComerciales && optionsComerciales.length > 0 && optionsComerciales.filter(p => p.mail == getEmail());

	const fetchAdjudicaciones = () => {
		const repoAdjudicaciones: AdjudicacionRepository = new AdjudicacionApiRepository(getToken());
		if (licitacion?.codigo && adjudicacion.length == 0) {
			repoAdjudicaciones.fetchAdjudicacion(licitacion?.codigo).then(respAdjudicacion => {
				if (respAdjudicacion && respAdjudicacion.length > 0) {
					setAdjudicacion(respAdjudicacion);
					if (respAdjudicacion.filter(p => p.contratistaId == PAVASAL_CONTRATISTAID).length > 0 && onField("tipoOfertaId").value !== TipoOfertaEnum.Interna){
						onField("tipoOfertaId").onSelectValue(TipoOfertaEnum.Interna);
						onField("estadoOfertaId").onSelectValue(EstadoOfertaEnum.Interna);
					}
				}
			});
		}
	}
	const onChangeCodigoPostal = (s: string) => {

		onField("codigoPostalId").onSelectValue(s[0]);

		if (!s || s.length == 0) {
			setRefreshProvincia(true);
			return;
		}

		getProvinciaByCPCode(s && s.length > 0 ? s[0].toString() : "").then((resp) => {
			onField("provinciaId").onSelectValue(resp.provinciaId);
			onField("provinciaDescripcion").onSelectValue(resp.provinciaDescripcion);
			setRefreshProvincia(false);
		});
	}


	const fetchTipoOferta = useCallback(() => {
		const repo: TipoOfertaRepository = new TipoOfertaApiRepository(getToken());
		repo.getAll().then((resp) => {
			setOptionsTipoOferta(resp);
		});
	}
		, [getToken]);

	const fetchComerciales = useCallback((delegacionId?: number) => {

		let filters: ComercialDtoFilter = {
			filter: {
				delegacionId: delegacionId ?? onField("delegacionId").value
			}
		}
		const repo: ComercialRepository = new ComercialApiRepository(getToken());
		repo.getConFiltro(filters).then((resp) => {
			setOptionsComerciales(resp);
			let email = getEmail();
			let comercial = resp.find(mail => mail.mail == email)
			setSelectedComercial(resp.find(mail => mail.mail == email) ?? selectedComercial);
			onField("comercialId").onSelectValue(comercial != null ? comercial.id : 100)
		});
	}
		, [getToken]);

	const fetchDelegaciones = useCallback(() => {
		const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
		return repo.fetchDelegaciones({
			filter: {
				ids: hasRole(USER_ROLES.ADMIN) ? COD_DELEGACIONES_CON_TODAS : [getDelegacion()?.id]
			}
		}).then((resp) => {
			setOptionsDelegaciones(resp);
			onField("delegacionId").onSelectValue(getDelegacion().id);
		})
	},
		[getToken])

	const handleClickDelegaciones = (delegacion: DelegacionDto) => {
		if ((selectedDelegacion.id !== delegacion.id)) {
			setSelectedDelegacion(delegacion);
		} else {
			setSelectedDelegacion({ id: "", descripcion: "" })
		}
	}

	const handleClickComercial = (comercial: ComercialDto) => {
		if ((selectedComercial.id !== comercial.id)) {
			setSelectedComercial(comercial);
		} else {
			setSelectedComercial({ id: 0, descripcion: "", delegacionId: Number(getDelegacion().id) })
		}
	}

	useEffect(() => { // Side Effect
		fetchDelegaciones();
		fetchTipoOferta();
		fetchComerciales(+getDelegacion().id);
	}, [fetchTipoOferta, fetchDelegaciones, fetchComerciales]);

	useEffect(() => { // Side Effect
		fetchAdjudicaciones();
	}, []);

	const handleClickTipoOferta = (e: TipoOferta) => {
		const newValue = e.id.toString();
		//si tiene codigo de licitacion no se puede cambiar el estado
		if (licitacion?.codigo && e.id != TipoOfertaEnum.Interna.toString()) {
			if (newValue != TipoOfertaEnum.Estudio.toString() && newValue != TipoOfertaEnum.Licitacion.toString()) {
				toast.warning("La oferta tiene asociada una licitacion, no se puede cambiar el estado");
				return;
			}
		}
		//no se puede poner el campo licitacion directamente
		if (!licitacion?.codigo && (newValue === TipoOfertaEnum.Licitacion.toString() || newValue === TipoOfertaEnum.Estudio.toString())) {
			toast.warning("Para poder ponerlo en estado de " + e.descripcion.toLowerCase() + " hay que añadir la licitacion");
			return;
		}

		if(newValue === TipoOfertaEnum.Interna.toString() && onField("clienteId").value != PAVASAL_CLIENTEID) {
			toast.warning("No se puede cambiar el Tipo de la oferta a Interna si el cliente seleccionado no es PAVASAL.");
			return;
		}

		onField("tipoOfertaId").onSelectValue(e.id); //para nuevos
	}

	const handleClienteSelected = (cliente: string[]) => {
		onField("clienteId").onSelectValue(cliente);

		if (+cliente != PAVASAL_CLIENTEID && onField("tipoOfertaId").value == TipoOfertaEnum.Interna)
		{
			onField("tipoOfertaId").onSelectValue(TipoOfertaEnum.Licitacion);
			toast.info("Tipo de la ofeerta cambiado de Interna a Licitación. Las ofertas de Tipo interna solo se pueden crear cuando el cliente es Pavasal")
		}

		const repo: ClienteDetalleRepository = new ClienteDetalleApiRepository(getToken());
		repo.searchCliente(Number.parseInt(cliente[0])).then((resp) => {
			onField("iva").onSelectValue(resp._iva);
		})
	}

	const formId = "ofertas-form";
	return (
		<>
			<FormTemplate
				className={formId}
				formId={formId}
				onSubmit={onSubmitForm}
			>
				<div className="row-of-three">
					{
						onField("tipoOfertaId").value !== TipoOfertaEnum.Licitacion && onField("tipoOfertaId").value !== TipoOfertaEnum.Estudio ?
							<FormField<SingleSuggestionInputFieldProps>
								label="Promotor"
								className={"op-input95"}
								error={onField("promotorId").error}
								onChange={onField("promotorId").onSelectValue}
								searchCallback={onSearchContratista}
								component={SingleSuggestionInputField}

								disabled={true}
							/>
							:
							<FormField<SingleSuggestionInputFieldProps>
								label="Promotor"
								className={"op-input95"}
								value={

									{
										id: onField("promotorId").value,
										text: onField("promotorDescripcion").value
									}

								}
								error={onField("promotorId").error}
								onChange={onField("promotorId").onSelectValue}
								searchCallback={onSearchContratista}
								component={SingleSuggestionInputField}

								disabled={(onField("tipoOfertaId").value == TipoOfertaEnum.Licitacion || onField("tipoOfertaId").value == TipoOfertaEnum.Estudio) && onField("promotorId").value != undefined}
							/>
					}

					<FormField<SingleSuggestionInputFieldProps>
						label="Cliente"
						value={
							{
								id: onField("clienteId").value,
								text: onField("clienteDescripcion").value
							}
						}
						error={onField("clienteId").error}
						onChange={handleClienteSelected}
						searchCallback={onSearchCliente}
						component={SingleSuggestionInputField}
						disabled={onField("tipoOfertaId").value == TipoOfertaEnum.Interna}
						required
						idTest="ClienteOferta"
					/>
					<FormField<SingleSuggestionInputFieldProps>
						label="Municipio"
						className={"op-input95"}
						value={

							{
								id: onField("codigoPostalId").value,
								text: onField("codigoPostalDescripcion").value
							}

						}
						required
						error={onField("codigoPostalId").error}
						onChange={(e) => { onChangeCodigoPostal(e) }}
						searchCallback={onSearchCodigoPostal}
						component={SingleSuggestionInputField}
						idTest="CodigoPostalOferta"
					/>
				</div>
				<div className="row-of-three">
					<FormInput
						label=" Fecha Grabación"
						value={onField("fechaOferta").value}
						error={onField("fechaOferta").error}
						onChange={onField("fechaOferta").onChangeValue}
						idTest="FechaOferta"
					/>
					{
						refreshProvincia ?
							<FormField<SingleSuggestionInputFieldProps>
								label="Provincia"
								className={"op-input95"}
								value={

									{
										id: onField("provinciaId").value,
										text: onField("provinciaDescripcion").value
									}

								}
								error={onField("provinciaId").error}
								onChange={onField("provinciaId").onSelectValue}
								searchCallback={onSearchProvincias}
								component={SingleSuggestionInputField}
								required
								disabled={onField("codigoPostalId").value}
								idTest="ProvinciaOferta"
							/> :
							<FormInput
								label="Provincia"
								disabled={true}
								value={onField("provinciaDescripcion").value}
								onChange={(e) => { }}
								idTest="ProvinciaOferta"
							/>
					}

					<FormField<DropdownProps>
						label="Delegación"
						options={optionsDelegaciones.map(
							(element) => {
								return {
									text: element.descripcion,
									id: element.id,
									selected: onField("delegacionId").value === element.id,
									// selected: selectedDelegacion.id == element.id ? true : false,
									// selected: getDelegacion().id === element.id,
									onClick: (e) => {
										onField("delegacionId").onSelectValue(element.id);
										handleClickDelegaciones(element);
										fetchComerciales(+element.id)
									}
								}
							})}
						error={onField("delegacionId").error}
						required={true}
						disabled={false}
						singleSelection={true}
						component={Dropdown}
					/>
				</div>
				<div className="row-of-three">
					<FormInput
						label="Obra"
						disabled={licitacion?.codigo !== undefined}
						value={onField("obraDescripcion").value}
						error={onField("obraDescripcion").error}
						onChange={onField("obraDescripcion").onChangeValue}
						required
						idTest="ObraOferta"
					/>
					<FormInput
						type="number"
						label="Toneladas"
						value={onField("toneladas").value}
						error={onField("toneladas").error}
						onChange={onField("toneladas").onChangeValue}
						required
						idTest="ToneladasOferta"
					/>
					<FormInput
						type="number"
						label="Iva"
						value={onField("iva").value}
						error={onField("iva").error}
						onChange={onField("iva").onChangeValue}
						required
						idTest="IvaOferta"
					/>
				</div>
				<div className="row-of-three">
					<FormInput
						type="number"
						label="Beneficio"
						value={onField("beneficio").value}
						error={onField("beneficio").error}
						onChange={onField("beneficio").onChangeValue}
						idTest="BeneficioOferta"
					/>
					<FormInput
						type="number"
						label="Gastos"
						value={onField("gastos").value}
						error={onField("gastos").error}
						onChange={onField("gastos").onChangeValue}
						idTest="GastosOferta"
					/>
					<FormInput
						type="number"
						label="Descuento"
						value={onField("descuento").value}
						error={onField("descuento").error}
						onChange={onField("descuento").onChangeValue}
						idTest="DescuentoOferta"
					/>
				</div>
				<div className="row-of-three">
					{optionsTipoOferta &&
						<FormField<DropdownProps>
							label="Tipo Oferta"
							options={optionsTipoOferta
								.filter(p => ofertaInternaSelectable() ? true : p.id != TipoOfertaEnum.Interna.toString())
								.map(
									(element) => {
										return {
											text: element.descripcion,
											id: element.id,
											selected: onField("tipoOfertaId").value === element.id,
											onClick: () => { handleClickTipoOferta(element); }
										}
									})}
							required={false}
							disabled={false}
							singleSelection={true}
							component={Dropdown}
							idTest="TipoOferta"
						/>

					}
					{optionsComerciales &&
						<FormField<DropdownProps>
							label="Comercial"
							options={optionsComerciales.map((element) => {
								return {
									text: element.descripcion,
									id: element.id.toString(),
									selected: onField("comercialId").value === element.id,
									onClick: () => {
										onField("comercialId").onSelectValue(element.id);
										handleClickComercial(element);
									}
								}
							})}
							required={false}
							disabled={false}
							singleSelection={true}
							component={Dropdown}
						/>
					}
				</div>
				<div className="single-row">
					<CustomTextInput
						label="Observaciones"
						name="observaciones"
						value={onField("observaciones").value}
						onChange={onField("observaciones").onChangeValue}
						resizable />
				</div>
			</FormTemplate>
		</>

	)
}

export default OfertasFormTemplate;