import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import { OportunidadFilter } from "../../domain/model/Oportunidad";
import { OportunidadesEndpoints } from "../oportunidades.api-endpoints";


export default class OportunidadesRequestDownloadExcelDetallado extends ApiRequest<
BaseFilter<OportunidadFilter>,
string>
{
	constructor(data: BaseFilter<OportunidadFilter>, token: string) {
		super(
			"POST",
			OportunidadesEndpoints.DOWNLOAD_EXCEL_DETALLADO,
			{ Authorization: token },
			data,
		)
	}
}