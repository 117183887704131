import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { toast } from "react-toastify";
import { COD_DELEGACIONES_CON_TODAS, NIL_UUID, USER_ROLES } from "../../../../../assets/constants/Constantes";
import CustomDropdownInput from "../../../../../components/CustomDropdownInput";
import IconButton from "../../../../../components/ui/atoms/Buttons/IconButton";
import Dropdown, { DropdownProps } from "../../../../../components/ui/atoms/Dropdown/Dropdown";
import FormInput from "../../../../../components/ui/atoms/Input/FormInput/FormInput";
import ModalPage from "../../../../../components/ui/atoms/ModalPage/ModalPage";
import FormField from "../../../../../components/ui/molecules/Form-field/FormField";
import TooltipComponent from "../../../../../components/ui/molecules/Tooltip/TooltipComponent";
import { useLoading } from "../../../../../context/Loading.Context";
import DelegacionesRepository from "../../../../../domain/DelegacionesRepository";
import UnidadesMedidaRepository from "../../../../../domain/UnidadesMedidaRepository";
import { DelegacionDto } from "../../../../../domain/model/Delegacion";
import UnidadMedida, { UnidadMedidaDto } from "../../../../../domain/model/UnidadMedida";
import { FormErrors } from "../../../../../hooks/use-form";
import DelegacionesApiRepository from "../../../../../infraestructure/api/Delegaciones.ApiRepository";
import UnidadesMedidaApiRepository from "../../../../../infraestructure/api/UnidadesMedida.ApiRepository";
import { CustomButton } from "../../../../../shared/components/Buttons/CustomButton";
import DeleteModal from "../../../../../shared/components/DeleteModal/DeleteModal";
import CustomInput from "../../../../../shared/components/Inputs/CustomInput/CustomInput";
import { formatDate2input } from "../../../../../utils/DateUtil";
import { formatNumber } from "../../../../../utils/NumberUtil";
import EstudioApiRepository from "../../../../Estudios/Infraestructure/api/Estudio.ApiRepository";
import EstudioDetalleApiRepository from "../../../../Estudios/Infraestructure/api/EstudioDetalle.ApiRepository";
import EstudioDetalleRepository from "../../../../Estudios/domain/EstudioDetalleRepository";
import EstudioRepository from "../../../../Estudios/domain/EstudioRepository";
import Estudio from "../../../../Estudios/domain/model/Estudio";
import EstudioDetalle from "../../../../Estudios/domain/model/EstudioDetalle";
import EstudioExport from "../../../../Estudios/domain/model/EstudioExport";
import { AuthContext } from "../../../../Login/AuthContextProvider";
import UnidadesRepository from "../../../../Unidades/domain/UnidadesRepository";
import { CalculoAvanzadoTipo, DescripcionUnidadObra, UnidadReadDto } from "../../../../Unidades/domain/model/Unidad";
import UnidadesApiRepository from "../../../../Unidades/infraestructure/api/UnidadesApiRepository";
import Ofertas from "../../../domain/model/Ofertas";
import "./EstudiosOfertas.ModalSection.scss";
import EstudioDetalleOrden from "./ModalOrden/EstudioDetalleOrden";
import CrearUnidadObra from "./ModalUnidadObra/CrearUnidadObra";
import CalculoAvanzado from "./ModalesCalculosAvanzados/CalculoAvanzado";
import CalculoAvanzadoFresado from "./ModalesCalculosAvanzados/CalculoAvanzadoFresado";
import CalculoAvanzadoPlantilla from "./ModalesCalculosAvanzados/CalculoAvanzadoPlantilla";
import CalculoAvanzadoZahorra from "./ModalesCalculosAvanzados/CalculoAvanzadoZahorra";
import { generateExcel, medicionByUnidadMedida, updateTotales } from "./ModalesCalculosAvanzados/Calculos";
import { BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnLink, BtnNumberedList, BtnRedo, BtnStrikeThrough, BtnStyles, BtnUnderline, BtnUndo, Editor, EditorProvider, HtmlButton, Separator, Toolbar } from 'react-simple-wysiwyg';
import useDebounce from "../../../../../hooks/useDebounce";
import { debounce } from "lodash";

//TOFIX: Select descripcion - default value

export interface EstudiosOfertasModalSectionProps {
	estudio: Estudio
	opened?: boolean
	oferta: Ofertas
	handleCloseModal: () => void
	handleRefresh: () => void,
	readOnly?: boolean
}

const EstudiosOfertasModalSection: React.FC<EstudiosOfertasModalSectionProps> = (
	{
		estudio,
		opened,
		oferta,
		handleCloseModal,
		handleRefresh,
		readOnly
	}
) => {
	const { getToken, getDelegacion, hasRole } = useContext(AuthContext);
	const { setLoading } = useLoading();
	const [estudioDetalleArray, setEstudioDetalleArray] = useState<EstudioDetalle[]>([]);
	const [unidades, setUnidades] = useState<UnidadReadDto[]>([]);
	const [showEstudios, setShowEstudios] = useState<boolean>(false);
	const [showOtherOferta, setShowOtherOferta] = useState<boolean>(false);
	const [estudios, setEstudios] = useState<Estudio[]>([]);
	const [selectedUnidad, setSelectedUnidad] = useState<UnidadReadDto>();
	const [selectedEstudio, setSelectedEstudio] = useState<Estudio>(estudio);
	const [importarEstudio, setImportarEstudio] = useState<Number>(Number.parseInt(oferta.codigo));
	const [coeficienteEstudio, setCoeficienteEstudio] = useState(estudio.coeficiente);
	const [estudioDetalleSingle, setEstudioDetalleSingle] = useState<EstudioDetalle>({
		id: '',
		unidadObraId: '',
		unidadMedida: {} as UnidadMedida,
		unidadMedidaId: '',
		descripcion: '',
		medicion: 0,
		precioCoste: 0,
		importeCoste: 0,
		coeficiente: coeficienteEstudio != 0 && coeficienteEstudio != undefined ? coeficienteEstudio : 1,
		precioVenta: 0,
		importeVenta: 0,
		estudioId: selectedEstudio.id,
		orden: 0,
		ordenDetalle: estudioDetalleArray.length == 0 ? 1 : Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) + 1
	});
	const [estudioDetalleEdit, setEstudioDetalleEdit] = useState<EstudioDetalle>({
		id: '',
		unidadObraId: '',
		unidadMedida: {} as UnidadMedida,
		unidadMedidaId: '',
		descripcion: '',
		medicion: 0,
		precioCoste: 0,
		importeCoste: 0,
		coeficiente: 0,
		precioVenta: 0,
		importeVenta: 0,
		estudioId: selectedEstudio.id,
		orden: 0,
		ordenDetalle: estudioDetalleArray.length == 0 ? 1 : Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) + 1
	});
	const [openUnidadObraModal, setOpenUnidadObraModal] = useState<boolean>(false)
	const [openEstudioDetalleOrden, setOpenEstudioDetalleOrden] = useState<boolean>(false)
	const [newUnidadObraCustom, setNewUnidadObraCustom] = useState<UnidadReadDto>();
	const [customId, setCustomId] = useState("1");
	const [descripciones, setDescripciones] = useState<string[]>([]);
	const [delegaciones, setDelegaciones] = useState<DelegacionDto[]>();
	const [selectedDelegacion, setSelectedDelegacion] = useState<DelegacionDto>();
	const [selectedCalculoAvanzadoEdit, setSelectedCalculoAvanzadoEdit] = useState<number>();

	const handleOpenModalUnidadObra = () => {
		setOpenUnidadObraModal(false);
	}

	const handleOpenEstudioDetalleOrden = (estudioDetalle: EstudioDetalle[]) => {
		saveEstudioDetalle(estudioDetalle);
		setOpenEstudioDetalleOrden(false);
	}
	const handleOpenEstudioDetalleOrdenCancel = () => {
		setOpenEstudioDetalleOrden(false);
	}

	const [openModal, setOpenModal] = useState(false);
	const [openModalEstudioDetalleNotas, setOpenModalEstudioDetalleNotas] = useState(false);
	const [estudioNotasSelected, setEstudioNotasSelected] = useState<string | undefined>();
	const [indexEstudioNotasSelected, setIndexEstudioNotasSelected] = useState<number>(-1);
	const [openModalActualizarTarifas, setOpenModalActualizarTarifas] = useState<boolean>(false);
	const [openDelete, setOpenDelete] = useState<boolean>(false);
	const [deletionId, setDeletionId] = useState<string>();
	const [deletionIndex, setDeletionIndex] = useState<number>(-1);

	const handleOpenModalFunc = (estudioDetallesArray: EstudioDetalle[]) => {
		setOpenModal(false);
		setSelectedUnidad({ id: "", descripcion: "", descripcionUnidadObra: [], delegacion: null, delegacionId: null, calculoAvanzadoId: null })
		estudioDetallesArray.map((estudioDetalle) => {
			if (estudioDetalle.estudioDetalleAvanzado != undefined)
			{
				let riegoTotal = estudioDetalle.estudioDetalleAvanzado.material?.filter(x => x.totalizarGrupo != undefined && x.totalizarGrupo == estudioDetalle.totalizarGrupo).reduce((partialSum, a) => partialSum + +a.riego, 0) ?? 0;
				

				if (estudioDetalle.totalizarGrupo != undefined)
					{	
						estudioDetalle.precioCoste = +estudioDetalle.precioCoste + +riegoTotal;

					} else {
						if (estudioDetalle.unidadMedidaId == "2")
						{
							estudioDetalle.precioCoste = +estudioDetalle.precioCoste + +(estudioDetalle.estudioDetalleAvanzado!.material != undefined ? estudioDetalle.estudioDetalleAvanzado!.material?.find( x => x.orden == estudioDetalle.orden)!.riego : 0) ;	
						} 
					}
			}

		})
		saveEstudioDetalle(estudioDetallesArray);
	}

	const totalVentas = (estudioDetalleArray && estudioDetalleArray.reduce((partialSum, a) => partialSum + a.importeVenta, 0)) ?? 0
	const totalCostes = (estudioDetalleArray && estudioDetalleArray.reduce((partialSum, a) => partialSum + a.importeCoste, 0)) ?? 0

	const fetchDetalleEstudio =
		() => {
			const repo: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());
			return repo.getByEstudioId(selectedEstudio.id);
		};

	useEffect(() => {
		fetchDelegaciones();
		fetchMedidas();
	}, [])

	useEffect(() => {
		if (estudio.id) {
			fetchDetalleEstudio().then((resp => {
				setEstudioDetalleArray(resp);
			}));
		}
	}, [estudio]);

	useEffect(() => {
		if (estudios && estudios.length > 0) {
			handleSelectEstudio(estudios[0].estudioId + " - " + estudios[0].descripcion); // This will be executed when the state changes
		}
	}, [estudios]);

	useEffect(() => {
		if (getDelegacion().id != "") {
			fetchUnidades(getDelegacion()?.id);
		}
	}, [getDelegacion]);

	const fetchUnidades = (id: string) => {
		const unidadesRepo: UnidadesRepository = new UnidadesApiRepository(getToken());
		setLoading(true);
		return unidadesRepo.getByDelegacion(id)
			.then((unidadesObraList) => {
				var unidades = unidadesObraList.map((unidad) => {
					return { ...unidad }
				})
				setUnidades(unidades)
			})
			.finally(() => { setLoading(false) });
	}

	const updateDescripcion = (descripcion: any) => {
		const maxorden = estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) : 0;
		setEstudioDetalleSingle({ ...estudioDetalleSingle, descripcion, ordenDetalle: estudioDetalleSingle.id != "" ? estudioDetalleSingle.ordenDetalle : maxorden + 1 });
	}

	const updateNotas = () => {
		let estudiosDetalle = [...estudioDetalleArray];
		estudiosDetalle[indexEstudioNotasSelected].notas = estudioNotasSelected;
		setLoading(true);
		updateEstudioDetalle(estudiosDetalle[indexEstudioNotasSelected])
			.then((response) => {
				setEstudioDetalleArray(estudiosDetalle);
				setOpenModalEstudioDetalleNotas(false);
				toast.success("Detalle del estudio actualizado con éxito");
			})
			.catch(() => {
				toast.error("Error al guardar el detalle del estudio");
			}).finally(() => {
				setLoading(false);
			});
	}

	const updateEstudioDetalle = (estudioDetalle: EstudioDetalle) => {
		const repo: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());
		return repo.update(estudioDetalle);
	}

	const allowZeroMedition = (medition: any) => {
		return medition == 0 ? 1 : medition;
	}
	const [medicionNull, setMedicionNull] = useState<number | null>(0)
	const updateMedicion = (medicion: any) => {
		const maxorden = estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) : 0;
		let med = medicion === "" ? null : Number(medicion);
		setMedicionNull(med);
		setEstudioDetalleSingle({
			...estudioDetalleSingle, medicion,
			importeCoste: +(allowZeroMedition(medicion) * (estudioDetalleSingle.precioCoste))?.toFixed(2),
			importeVenta: +(medicion * (estudioDetalleSingle.precioVenta))?.toFixed(2),
			ordenDetalle: estudioDetalleSingle.id != "" ? estudioDetalleSingle.ordenDetalle : maxorden + 1
		});
	}
	const updatePrecioCoste = (precioCoste: any) => {
		const maxorden = estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) : 0;
		//precioCoste === "" ? null : Number(precioCoste);
		setEstudioDetalleSingle({
			...estudioDetalleSingle, precioCoste,
			importeCoste: +(+precioCoste * (allowZeroMedition(estudioDetalleSingle.medicion)))?.toFixed(2),
			precioVenta: +((+precioCoste) / estudioDetalleSingle.coeficiente)?.toFixed(2),
			importeVenta: +(+((+precioCoste / estudioDetalleSingle.coeficiente)).toFixed(2) * (estudioDetalleSingle.medicion)).toFixed(2),
			ordenDetalle: estudioDetalleSingle.id != "" ? estudioDetalleSingle.ordenDetalle : maxorden + 1
		});
	}


	const addCoeficienteEstudio = (coeficiente: any) => {
		setCoeficienteEstudio(coeficiente);
	}

	const fetchDelegaciones = useCallback(() => {
		const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
		return repo.fetchDelegaciones({
			filter: {
				ids: COD_DELEGACIONES_CON_TODAS
			}
		}).then((resp) => {
			setDelegaciones(resp);
			setSelectedDelegacion(getDelegacion());
			if (getDelegacion().id !== "") {
				fetchUnidades(getDelegacion().id);
			}
		})
	}, [getToken])

	const updateCoeficienteEstudio = () => {
		setEstudioDetalleSingle({
			...estudioDetalleSingle, coeficiente: coeficienteEstudio != 0 && coeficienteEstudio != undefined ? coeficienteEstudio : 1
		});
		updateCampoEstudio(coeficienteEstudio, 'coeficiente')

		//Si no hay detalles no hay que crear estudio de momento
		if (estudioDetalleArray.length == 0) {
			return;
		}
		if (selectedEstudio.id !== "") {
			saveEstudio(coeficienteEstudio)
		}
		if (estudioDetalleArray.length >= 1) {
			estudioDetalleArray.forEach((estudioDetalle, index) => {
				estudioDetalle.coeficiente = coeficienteEstudio ?? estudioDetalle.coeficiente

				if (estudioDetalle.estudioDetalleAvanzado != null) {

					let toneladasTotales = estudioDetalle.estudioDetalleAvanzado.material?.reduce((partialSum, a) => partialSum + +a.toneladas, 0) ?? 0;
					
					let equipoEuroTm: number = 0;
					let trasladoEuroTm: number = 0;
					
					equipoEuroTm = +((estudioDetalle.estudioDetalleAvanzado?.equipo!.reduce((partialSum, a) => 
						partialSum + (+a.dias * +a.tarifa), 0) ?? 1) / toneladasTotales).toFixed(2);
					trasladoEuroTm = +((estudioDetalle.estudioDetalleAvanzado?.traslado!.reduce((partialSum, a) => 
						partialSum + (+a.dias * +a.tarifa), 0) ?? 1) / toneladasTotales).toFixed(2);

					let equipoTrasladoEuroTm: number = equipoEuroTm + trasladoEuroTm;
					const tramoTarifa = estudioDetalle.estudioDetalleAvanzado?.tramo?.length != 0 ? +estudioDetalle.estudioDetalleAvanzado.tramo![0].tarifa : 0;
					
					let precioVentaTotal = 0;
					if (estudioDetalle.totalizarGrupo != undefined)
					{
						//let riegoTotal = estudioDetalle.estudioDetalleAvanzado.material?.filter(x => x.totalizarGrupo != undefined && x.totalizarGrupo == estudioDetalle.totalizarGrupo).reduce((partialSum, a) => partialSum + +a.riego, 0) ?? 0;
						estudioDetalle.estudioDetalleAvanzado.material?.forEach(material => {
							material.coeficiente = coeficienteEstudio ?? material.coeficiente;
							
							if (estudioDetalle.totalizarGrupo == material.totalizarGrupo)
							{
							
								material!.totalEurosM2 = +(+material!.riego + +(+material!.baremo + equipoTrasladoEuroTm + tramoTarifa).toFixed(2) * (+material!.espesor / 100) * +material!.densidad).toFixed(2);   
								let totales = updateTotales(material,equipoTrasladoEuroTm,tramoTarifa,+estudioDetalle.unidadMedidaId,estudioDetalle.unidadObra?.calculoAvanzadoId)
								precioVentaTotal += +estudioDetalle.unidadMedidaId == 2 ? totales.precioVentaM2! : totales.precioVentaTm!;
							}						
						});

						//estudioDetalle.precioCoste = estudioDetalle.precioCoste + riegoTotal;
						estudioDetalle.precioVenta = +(+precioVentaTotal).toFixed(2)
						estudioDetalle.importeVenta = +(estudioDetalle.precioVenta * (estudioDetalle.medicion)).toFixed(2);
					} else {

						estudioDetalle.estudioDetalleAvanzado.material?.forEach(material => {
							material.coeficiente = coeficienteEstudio ?? material.coeficiente;
							if (estudioDetalle.orden = material.orden)
							{
								if(+estudioDetalle.unidadMedidaId == 2)
								{
									//estudioDetalle.precioCoste = +(estudioDetalle.precioCoste + material.riego).toFixed(2);
									estudioDetalle.precioVenta = +(estudioDetalle.precioCoste / estudioDetalle.coeficiente).toFixed(2)
									estudioDetalle.importeVenta = +(estudioDetalle.precioVenta * (estudioDetalle.medicion)).toFixed(2);
								} else {
									estudioDetalle.precioVenta = +(estudioDetalle.precioCoste / estudioDetalle.coeficiente).toFixed(2)
									estudioDetalle.importeVenta = +(estudioDetalle.precioVenta * (+material.toneladas)).toFixed(2);
								}
								
							}

						});


					}

				} else {
					estudioDetalle.precioVenta = +(estudioDetalle.precioCoste / +coeficienteEstudio!).toFixed(2)
					estudioDetalle.importeVenta = +((estudioDetalle.precioVenta) * (estudioDetalle.medicion)).toFixed(2)
				}
			})
			if (selectedEstudio.id !== "") {
				saveEstudioDetalle(estudioDetalleArray)
			}
		}
		toast.success("Coeficiente del estudio cambiado")
	}

	const updateCoeficiente = (coeficiente: any) => {
		const maxorden = estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) : 0;
		setEstudioDetalleSingle({
			...estudioDetalleSingle, coeficiente,
			precioVenta: +((estudioDetalleSingle.precioCoste) / coeficiente)?.toFixed(2),
			importeVenta: +(+((estudioDetalleSingle.precioCoste) / coeficiente).toFixed(2) * (estudioDetalleSingle.medicion)).toFixed(2),
			ordenDetalle: estudioDetalleSingle.id != "" ? estudioDetalleSingle.ordenDetalle : maxorden + 1
		});
	}

	const updatePrecioVenta = (precioVenta: any) => {
		const maxorden = estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) : 0;
		setEstudioDetalleSingle({ ...estudioDetalleSingle, precioVenta, importeVenta: +(precioVenta * (estudioDetalleSingle.medicion)).toFixed(2), ordenDetalle: estudioDetalleSingle.id != "" ? estudioDetalleSingle.ordenDetalle : maxorden + 1 });
	}

	const updateCampoEstudio = (newValue: any, propertyValue: string) => {
		setSelectedEstudio({ ...selectedEstudio, [propertyValue]: newValue })
	}

	function validate(data: EstudioDetalle): boolean {
		let errors: FormErrors<EstudioDetalle> = {};
		const fieldIsRequired = "Campo obligatorio.";
		if (data.descripcion === undefined || data.descripcion == "") {
			errors = { ...errors, descripcion: fieldIsRequired };
		}
		if (data.medicion <= 0) {
			errors = { ...errors, medicion: fieldIsRequired }
		}
		if (data.coeficiente <= 0) {
			errors = { ...errors, coeficiente: fieldIsRequired }
		}
		if (data.precioCoste <= 0) {
			errors = { ...errors, precioCoste: fieldIsRequired }
		}

		return Object.keys(errors).length == 0;
	}

	const saveEstudioDetalle = async (estudioDetallesArray: EstudioDetalle[]) => {
		if (estudioDetalleSingle != undefined && !validate(estudioDetalleSingle) && estudioDetallesArray.length == 0) {
			if (!coeficienteEstudio) {
				toast.error("Complete los campos obligatorios para añadir un detalle al estudio");
				return;
			}
		} else if (estudioDetalleSingle != undefined && estudioDetallesArray.length == 0) {
			estudioDetallesArray.push(estudioDetalleSingle);
		}

		// Si hay estudio
		if (selectedEstudio.id) {
			updateEstudio(selectedEstudio, estudioDetallesArray);
		} else {
			createEstudio(selectedEstudio, estudioDetallesArray);
		}
	}

	const updateEstudio = (selectedEstudio: Estudio, estudioDetalles: EstudioDetalle[]) => {
		let estudioDetallesUpdate = [...estudioDetalles];
		for (let estudioDetalle of estudioDetalles) {
			estudioDetalle.estudioId = estudioDetalle.estudioId ?? selectedEstudio.id;

			// Actualizar detalle del estudio cuando ya existe el estudio
			if (!estudioDetalle.id) {
				estudioDetalle.id = NIL_UUID;

				// Insertar detalle del estudio cuando ya existe el estudio
				if (!isNaN(+estudioDetalle.unidadObraId) && parseInt(estudioDetalle.unidadObraId) < 10) {
					estudioDetalle.unidadObraId = "";
				}
			}

		};

		const repo: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());
		repo.updateDetallePorEstudio(estudioDetalles).then(resp => {
			toast.success(`Detalle del estudio modificado con éxito`);
			fetchDetalleEstudio().then((resp => {
				setEstudioDetalleArray(resp);
				cleanEstudioDetalles(resp.length + 1);
			}))
				.finally(() => {
					setLoading(false);
					setDescripcionUnidadObraSelected(false);
				});
		});
	}

	const cleanEstudioDetalles = (ordenDetalle: number) => {
		setEstudioDetalleSingle({
			id: '',
			unidadObraId: '',
			unidadMedida: {} as UnidadMedida,
			unidadMedidaId: '',
			descripcion: '',
			medicion: 0,
			precioCoste: 0,
			importeCoste: 0,
			coeficiente: coeficienteEstudio != 0 && coeficienteEstudio != undefined ? coeficienteEstudio : 1,
			precioVenta: 0,
			importeVenta: 0,
			estudioId: selectedEstudio.id,
			orden: 0,
			ordenDetalle: ordenDetalle
		});
	};

	const createEstudio = (selectedEstudio: Estudio, estudioDetallesArray: EstudioDetalle[]) => {
		try {
			const repo: EstudioRepository = new EstudioApiRepository(getToken());
			const repoDetalle: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());
			setLoading(true);

			repo.add(selectedEstudio).then(resp => {
				let estudioDetalleArrayUpdated: EstudioDetalle[] = estudioDetalleArray.concat(estudioDetallesArray);
				estudioDetalleArrayUpdated.forEach((item) => {
					item.estudioId = resp.id;
					if (!isNaN(+item.unidadObraId) && parseInt(item.unidadObraId) < 10) {
						item.unidadObraId = "";
					}
				});

				// Se crean todos los detalles del estudio
				repoDetalle.addList(estudioDetalleArrayUpdated)
					.then(estudioDetallesInserted => {
						toast.success(`Estudio añadido con éxito`);
						repoDetalle.getByEstudioId(resp.id).then(estudioDetallesresp => {
							setSelectedEstudio({
								id: resp.id,
								estudioId: resp.estudioId,
								ofertaId: resp.ofertaId,
								creationTime: formatDate2input(resp.creationTime),
								descripcion: resp.descripcion,
								descripcionPresupuesto: resp.descripcionPresupuesto,
								estudioDetalle: estudioDetallesresp,
								numeroPresupuestoYVersion: resp.numeroPresupuestoYVersion,
								presupuestoId: resp.presupuestoId,
								coeficiente: coeficienteEstudio ?? 0
							});
							setEstudioDetalleSingle({
								id: '',
								unidadObraId: '',
								unidadMedida: {} as UnidadMedida,
								unidadMedidaId: '',
								descripcion: '',
								medicion: 0,
								precioCoste: 0,
								importeCoste: 0,
								coeficiente: coeficienteEstudio != 0 && coeficienteEstudio != undefined ? coeficienteEstudio : 1,
								precioVenta: 0,
								importeVenta: 0,
								estudioId: resp.id,
								orden: 0,
								ordenDetalle: 1
							});
							setEstudioDetalleArray(estudioDetallesresp);
							setDescripcionUnidadObraSelected(false)
						})
					});
			})
				.finally(() => {
					setLoading(false);
				});
		}
		catch (err) {
			console.error(err);
		}
	}

	const handleSelectUnidadObra = (unidad: UnidadReadDto) => {
		if (!selectedUnidad || selectedUnidad.id != unidad.id) {
			setDescripcionUnidadObraSelected(false)
			setEstudioDetalleSingle(prev => ({ ...prev, unidadObraId: unidad.id!.toString(), descripcion: "" }))
			setSelectedUnidad(unidad)
			let unidadPrueba = unidad?.descripcionUnidadObra.filter(x => x.unidadMedida.id == newMedida.id.toString())
			if (unidadPrueba) {
				setDescripcionUnidad(unidadPrueba)
			}

			if (unidad?.calculoAvanzadoId) {
				setSelectedCalculoAvanzadoEdit(unidad?.calculoAvanzadoId)
			}
		} else {
			setSelectedUnidad({ id: "", descripcion: "", descripcionUnidadObra: [], delegacion: null, delegacionId: null, calculoAvanzadoId: null })
			setDescripcionUnidad([])
		}
	}

	const [descripcionUnidadObraSelected, setDescripcionUnidadObraSelected] = useState<boolean>(false)

	const handleChangeDescription = (descripcionUnidad: DescripcionUnidadObra) => {
		setDescripcionUnidadObraSelected(true)
		setEstudioDetalleSingle(prev => ({ ...prev, descripcion: descripcionUnidad.descripcion }))
		setEstudioDetalleSingle(prev => ({ ...prev, unidadMedidaId: descripcionUnidad.unidadMedida.id.toString(), unidadMedida: descripcionUnidad.unidadMedida }))
	}

	const saveEstudio = async (coeficiente?: any) => {
		const repo: EstudioRepository = new EstudioApiRepository(getToken());
		const repoDet: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());

		if (selectedEstudio.id) {
			try {
				setLoading(true);
				if (coeficiente != null || coeficiente != undefined) {
					selectedEstudio.coeficiente = coeficiente;
				}
				if (selectedEstudio.descripcion === "" || selectedEstudio.descripcion == null || selectedEstudio === undefined) {
					toast.warning("El nombre del estudio no puede estar vacio")
					setLoading(false);
					return;
				}
				await repo.update(selectedEstudio)
					.finally(() => {
						setLoading(false);
					});
				toast.success(`Estudio actualizado con éxito`);
				if (coeficiente == null || coeficiente == undefined) {
					handleRefresh();
				}
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		} else {
			try {
				setLoading(true);
				if (coeficiente != null || coeficiente != undefined) {
					selectedEstudio.coeficiente = coeficiente;
				}
				if (selectedEstudio.descripcion === "" || selectedEstudio.descripcion == null || selectedEstudio === undefined) {
					toast.warning("El nombre del estudio no puede estar vacío")
					setLoading(false);
					return;
				}

				repo.add(selectedEstudio)
					.then((resp) => {

						setSelectedEstudio({ ...selectedEstudio, id: resp.id, estudioId: resp.estudioId })
						const estudioDetalleUpdated = estudioDetalleArray?.map((detalle) => {
							return { ...detalle, estudioId: resp.id, id: '' };
						})

						repoDet.addList(estudioDetalleUpdated).then((respDetalle) => {
							setEstudioDetalleSingle({ ...estudioDetalleSingle, estudioId: resp.id, coeficiente: coeficiente ?? 1 });
							setEstudioDetalleArray(respDetalle);
							toast.success(`Estudio añadido con éxito`);
							if ((coeficiente == null || coeficiente == undefined)) {
								handleRefresh();
							}
						}).catch((e) => {
							toast.success(`Error añadiendo el estudio`);
						});

					})
					.finally(() => {
						setLoading(false);
					});
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		}
	}

	const editarDetalleEstudios = (estudioDetalle: EstudioDetalle, index: number) => {
		let descripcionesEstudioDetalles: string[] = [];

		estudioDetalleArray.filter(x => x.estudioDetalleAvanzadoId == estudioDetalle.estudioDetalleAvanzadoId &&
			x.estudioDetalleAvanzadoId != undefined)
			.forEach(element => {
				descripcionesEstudioDetalles.push(element.descripcion);
			});

		if (estudioDetalle.estudioDetalleAvanzado) {
			setSelectedCalculoAvanzadoEdit(estudioDetalle.estudioDetalleAvanzadoId ? estudioDetalle.unidadObra?.calculoAvanzadoId : undefined);
			setSelectedUnidad(undefined);
			setDescripciones(descripcionesEstudioDetalles);
			setEstudioDetalleEdit(estudioDetalle);
			setOpenModal(true);
		} else {
			setEstudioDetalleSingle({ ...estudioDetalle, unidadMedidaId: estudioDetalle.unidadMedida.id.toString(), descripcion: estudioDetalle.descripcion });
			setDescripcionUnidadObraSelected(true)
			setSelectedUnidad(unidades.find(x => x.id == estudioDetalle.unidadObraId))
			handleSelectMedida(medidas.find(x => x.id == estudioDetalle.unidadMedidaId), unidades.find(x => x.id == estudioDetalle.unidadObraId))
		}
	}

	const handleDelegacionSelected = (item: DelegacionDto) => {
		if (item.id != "") {
			fetchUnidades(item.id)
			setSelectedDelegacion(item)
			reiniciarUnidadObraSeleccionada();
		}
	}

	const reiniciarUnidadObraSeleccionada = () => {
		setUnidades([]);
		setDescripciones([]);
		setSelectedUnidad(undefined)
		handleChangeDescription({
			id: '',
			unidadObraId: '',
			unidadMedida: { id: '0', descripcion: '' } as UnidadMedida,
			unidadMedidaId: 0,
			descripcion: ''
		})
	}

	// delete
	const openConfirmationModal = (id: string, index: number) => {
		setDeletionId(id);
		setDeletionIndex(index);
		setOpenDelete(true);
	}

	const handleCloseModalDelete = (remove: boolean) => {
		setOpenDelete(false);
		if (remove) {
			borrarDetalleEstudios(deletionId!, deletionIndex!);
		}
	}

	const borrarDetalleEstudios = (id: string, index: number) => {
		const repo: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());
		const repoEstudio: EstudioRepository = new EstudioApiRepository(getToken());

		if (!estudioDetalleArray[index].id) {
			setEstudioDetalleArray(estudioDetalleArray.filter((x, i) => i !== index));
			toast.success(`Eliminado correctamente`);
			return;
		}
		repo.delete(id)
			.then(resp => {
				toast.success('Eliminado con éxito');
				fetchDetalleEstudio().then((resp => {
					// para que al salir no se quede un estudio vacio
					if (resp.length == 0) {
						repoEstudio.delete(selectedEstudio.id).then(resp => {
							setSelectedEstudio({
								id: "",
								estudioId: undefined,
								ofertaId: estudio.ofertaId ?? "",
								creationTime: formatDate2input(estudio.creationTime) ?? "",
								descripcion: estudio.descripcion ?? selectedEstudio.descripcion,
								estudioDetalle: [],
								numeroPresupuestoYVersion: "",
								presupuestoId: "",
								coeficiente: coeficienteEstudio ?? 0
							});
						})
					}
					setEstudioDetalleArray(resp);
				}));
			})
			.catch((error) => {
				toast.error(`Error en la eliminación: ${error}`);
			})
			.finally(() => {
				setOpenDelete(false)
			});
	}

	const onCheckImport = async () => {

		setShowEstudios(!showEstudios)
		if (!showEstudios) {
			setShowOtherOferta(true)
			onCheckImportOtherOferta(+importarEstudio)
		} else {
			setShowOtherOferta(false);
			setEstudioDetalleArray([]);
		}
	}

	const debouncedSearch = useMemo(() => {
		return debounce((value: number) => fetchEstudios(value), 1000)
	}, []);


	const onCheckImportOtherOferta = async (ofertaId: number) => {
		if (showEstudios) {
			if (ofertaId.toString() === '') {
				debouncedSearch(+oferta.codigo)
			} else {
				if (ofertaId != 0) {
					debouncedSearch(ofertaId)
				} else {
					setEstudios([]);
					setEstudioDetalleArray([]);
				}
			}
			setImportarEstudio(ofertaId)
		} else {
			setEstudios([]);
			setEstudioDetalleArray([]);
			// setImportarEstudio(0)
			if (ofertaId != 0) {
				debouncedSearch(+importarEstudio)
			} else {
				debouncedSearch(+oferta.codigo)
			}
		}
	}

	const exportExcel = () => {
		const repo: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());
		let estudioExportArray: EstudioExport[] = [];
		let estudioExport: EstudioExport;
		let estudioDetalle: EstudioDetalle[] = estudioDetalleArray.filter(x => (x.estudioDetalleAvanzadoId != null));
		let plantilla = 0;
		estudioDetalle.forEach(element => {
			const calculoAvanzadoId = element.unidadObra?.calculoAvanzadoId;

			if (estudioExportArray.length === 0 || (!estudioExportArray.find(x => x.estudioDetalle[0].CalculoAvanzadoId === calculoAvanzadoId))) {
				if (calculoAvanzadoId == CalculoAvanzadoTipo.PLANTILLA) {
					estudioExport = generateExcel([element], oferta, selectedEstudio);
					estudioExportArray.push(estudioExport);
				} else {
					estudioExport = generateExcel(estudioDetalle.filter(x => (x.unidadObra?.calculoAvanzadoId === calculoAvanzadoId)), oferta, selectedEstudio);
					estudioExportArray.push(estudioExport);
				}
			}
			if (estudioExportArray.find(x => x.estudioDetalle[0].CalculoAvanzadoId === CalculoAvanzadoTipo.PLANTILLA) && plantilla > 0 && calculoAvanzadoId == CalculoAvanzadoTipo.PLANTILLA) {
				estudioExport = generateExcel([element], oferta, selectedEstudio);
				estudioExportArray.push(estudioExport);
			}
			if (estudioExportArray.find(x => x.estudioDetalle[0].CalculoAvanzadoId === CalculoAvanzadoTipo.PLANTILLA && calculoAvanzadoId == CalculoAvanzadoTipo.PLANTILLA)) {
				plantilla++;
			}
		});

		return repo.export(estudioExportArray).then((url) => {
			window.open(url);
		});
	}

	const fetchEstudios = useCallback((id: number) => {
		const repo: EstudioRepository = new EstudioApiRepository(getToken());
		return repo.getByOfertaCodigo(id, +getDelegacion().id).then((estudiosList) => {
			const estudiosResponse = estudiosList.map((estudio) => {
				return { ...estudio, creationTime: estudio.creationTime ? formatDate2input(estudio.creationTime) : '' }
			});
			if (estudiosResponse.length < 1) {
				toast.info("El código de oferta no existe, no tiene estudios asociados o no pertenece a la delegación del usuario.")
			}
			setEstudios(estudiosResponse);

		})
	}, [getToken]);

	const handleSelectEstudio = async (e: any) => {
		const estudioObj = estudios.find((el: any) => el.estudioId + " - " + el.descripcion === e)

		setSelectedEstudio({
			id: "",
			estudioId: estudioObj?.estudioId,
			ofertaId: oferta.id ?? "",
			creationTime: formatDate2input(estudioObj?.creationTime ?? ""),
			descripcion: "Copia de " + estudioObj?.descripcion,
			descripcionPresupuesto: estudioObj?.descripcionPresupuesto,
			estudioDetalle: [],
			numeroPresupuestoYVersion: '0',
			presupuestoId: '',
			coeficiente: coeficienteEstudio ?? 0
		})

		setEstudioDetalleSingle({
			...estudioDetalleSingle,
			id: '',
			unidadObraId: "",
			unidadMedida: {} as UnidadMedida,
			unidadMedidaId: '',
			descripcion: "",
			medicion: 0,
			precioCoste: 0,
			importeCoste: 0,
			coeficiente: coeficienteEstudio != 0 && coeficienteEstudio != undefined ? coeficienteEstudio : 1,
			precioVenta: 0,
			importeVenta: 0,
			estudioId: selectedEstudio ? selectedEstudio.id : ""
		})

		estudioObj?.estudioDetalle.forEach((detalle) => {
			detalle.id = "";
			detalle.estudioId = "";
		})

		setEstudioDetalleArray(estudioObj?.estudioDetalle ?? []);

	}

	const crearUnidadObra = () => {
		setOpenUnidadObraModal(true);
	}

	const estudioDetalleOrden = () => {
		setOpenEstudioDetalleOrden(true);
	}

	const cancelEstudioDetalleAvanzado = () => {
		setOpenModal(false);

		if (selectedUnidad?.calculoAvanzadoId) {
			setSelectedCalculoAvanzadoEdit(selectedUnidad.calculoAvanzadoId);
		}

	}

	const addUnidadOfertaCustom = (unidadObra: UnidadReadDto) => {
		let unidad: UnidadReadDto = unidadObra;
		if (newUnidadObraCustom) {
			unidad.id = customId;
			unidades.push(unidad);
			let customId2: number = parseInt(customId) + 1;
			setNewUnidadObraCustom(unidadObra)
			setCustomId(customId2.toString())
			handleSelectUnidadObra(unidadObra);
			handleSelectMedida(unidadObra.descripcionUnidadObra[0].unidadMedida, unidadObra);
			if (unidadObra?.descripcionUnidadObra.length > 0) {
				setEstudioDetalleSingle({ ...estudioDetalleSingle, descripcion: unidadObra.descripcionUnidadObra[0]?.descripcion, unidadMedidaId: unidadObra.descripcionUnidadObra[0].unidadMedida.id })

				setDescripcionUnidad([{
					id: "",
					unidadObraId: unidadObra.descripcionUnidadObra[0]?.unidadObraId,
					descripcion: unidadObra.descripcionUnidadObra[0]?.descripcion,
					unidadMedidaId: unidadObra.descripcionUnidadObra[0]?.unidadMedidaId,
					unidadMedida: unidadObra.descripcionUnidadObra[0]?.unidadMedida
				}]);

			}

		} else {
			setNewUnidadObraCustom(unidadObra);
			unidades.push(unidadObra);
			handleSelectUnidadObra(unidadObra);
			handleSelectMedida(unidadObra.descripcionUnidadObra[0].unidadMedida, unidadObra);
			if (unidadObra?.descripcionUnidadObra.length > 0) {
				setEstudioDetalleSingle({ ...estudioDetalleSingle, descripcion: unidadObra.descripcionUnidadObra[0]?.descripcion, unidadMedidaId: unidadObra.descripcionUnidadObra[0]?.unidadMedida.id, unidadMedida: unidadObra.descripcionUnidadObra[0]?.unidadMedida })
				setDescripcionUnidad([{
					id: "",
					unidadObraId: unidadObra.descripcionUnidadObra[0]?.unidadObraId,
					descripcion: unidadObra.descripcionUnidadObra[0]?.descripcion,
					unidadMedidaId: unidadObra.descripcionUnidadObra[0]?.unidadMedidaId,
					unidadMedida: unidadObra.descripcionUnidadObra[0]?.unidadMedida
				}]);
			}
		}

		setDescripcionUnidadObraSelected(true);
	}

	// Actualiza las tarifas con los valores actuales
	const updateTarifas = async () => {
		const repo: EstudioRepository = new EstudioApiRepository(getToken());
		try {
			setLoading(true);
			await repo.updateTarifas(selectedEstudio.id).
				then((resp) => {
					setEstudioDetalleArray(resp);
					toast.success(`Tarifas actualizadas con éxito`);
				}).finally(() => {
					setOpenModalActualizarTarifas(false);
					setLoading(false);
				});
		} catch (err) {
			console.error(err)
		}
	};

	const medicionZero = (number: number, venta?: boolean) => {
		if (+number === 0) {
			if (estudioDetalleArray.length > 0) {
				if (venta) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		} else {
			return false
		}
	}

	const detallesZero = (item: EstudioDetalle, index: number) => {
		if (estudioDetalleArray.length >= 2) {
			estudioDetalleArray.forEach((estudio) => {
				return estudio.importeVenta === 0 && item.importeVenta !== estudio.importeVenta;
			});
		}

		return false;
	}


	const openModelCalculoAvanzado = () => {
		if (selectedUnidad?.calculoAvanzadoId == CalculoAvanzadoTipo.MBC && estudioDetalleArray.filter(x => x.unidadObra?.calculoAvanzadoId && x.unidadObra?.calculoAvanzadoId == CalculoAvanzadoTipo.MBC).length > 0) {
			toast.error("No se puede añadir más de una unidad de obra MBC por estudio.");
			return;
		}

		if (selectedUnidad?.calculoAvanzadoId == CalculoAvanzadoTipo.FRESADO && estudioDetalleArray.filter(x => x.unidadObra?.calculoAvanzadoId && x.unidadObra?.calculoAvanzadoId == CalculoAvanzadoTipo.FRESADO).length > 0) {
			toast.error("No se puede añadir más de una unidad de obra FRESADO por estudio.");
			return;
		}
		if (selectedUnidad?.calculoAvanzadoId == CalculoAvanzadoTipo.ZAHORRA && estudioDetalleArray.filter(x => x.unidadObra?.calculoAvanzadoId && x.unidadObra?.calculoAvanzadoId == CalculoAvanzadoTipo.ZAHORRA).length > 0) {
			toast.error("No se puede añadir más de una unidad de obra ZAHORRA por estudio.");
			return;
		}
		setEstudioDetalleEdit({} as EstudioDetalle);
		setDescripciones([estudioDetalleSingle?.descripcion]);
		setOpenModal(true);
	}

	const [newMedida, setNewMedida] = useState({
		id: 1,
		descripcion: ""
	})

	const [medidas, setMedidas] = useState<UnidadMedidaDto[]>([]);
	const [descipcionUnidad, setDescripcionUnidad] = useState<DescripcionUnidadObra[]>([]);

	const handleSelectMedida = (medidaObj: any, selectedUnidadForce?: UnidadReadDto) => {
		setNewMedida(medidaObj);
		let unidadDesc: DescripcionUnidadObra[] = []
		let unidad = selectedUnidad ?? selectedUnidadForce
		let unidadPrueba = unidad?.descripcionUnidadObra.filter(x => x.unidadMedida.id == medidaObj.id)
		if (unidadPrueba) {
			setDescripcionUnidad(unidadPrueba);
		}
	}

	const fetchMedidas = useCallback(() => {
		const repo: UnidadesMedidaRepository = new UnidadesMedidaApiRepository(getToken());
		return repo.getConFiltro({}).then((data) => {
			setMedidas(data);
		})
			.catch(message => toast.error(message));
	}, []);

	const estudioAvanzadoComponent = () => {
		let tipoEstudioAvanzado = selectedCalculoAvanzadoEdit ?? selectedUnidad?.calculoAvanzadoId;
		switch (tipoEstudioAvanzado) {
			case 1:
				return <CalculoAvanzado
					openModal={openModal}
					handleCloseModal={handleOpenModalFunc}
					handleCloseCancelModal={cancelEstudioDetalleAvanzado}
					unidadObraId={selectedUnidad?.id ?? estudioDetalleEdit.unidadObraId ?? undefined}
					unidadObraDescripcion={selectedUnidad?.descripcion ?? estudioDetalleEdit.descripcion ?? undefined}
					estudioDetalleDescripcion={estudioDetalleEdit.estudioDetalleAvanzado ? descripciones : [estudioDetalleSingle.descripcion]}
					unidadMedida={estudioDetalleEdit.estudioDetalleAvanzado ? estudioDetalleEdit.unidadMedida : estudioDetalleSingle.unidadMedida}
					estudioDetalle={estudioDetalleEdit}
					estudioDetalleAvanzado={estudioDetalleEdit.estudioDetalleAvanzado}
					estudioDetalleId={estudioDetalleEdit.id ?? undefined}
					estudioDetalleAvanzadoId={estudioDetalleEdit.estudioDetalleAvanzadoId}
					coeficienteEstudio={coeficienteEstudio ?? 1}
					delegacionId={selectedDelegacion?.id ?? getDelegacion().id}
					readOnly={readOnly}
					estudiosDetalleLength={estudioDetalleEdit.ordenDetalle && estudioDetalleEdit.id != undefined ? estudioDetalleEdit.ordenDetalle : estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) + 1 : 1}
				/>
			case 2:
				return <CalculoAvanzadoFresado
					openModal={openModal}
					handleCloseModal={handleOpenModalFunc}
					handleCloseCancelModal={cancelEstudioDetalleAvanzado}
					unidadObraId={selectedUnidad?.id ?? estudioDetalleEdit.unidadObraId ?? undefined}
					unidadObraDescripcion={selectedUnidad?.descripcion ?? estudioDetalleEdit.descripcion ?? undefined}
					estudioDetalleDescripcion={estudioDetalleEdit.estudioDetalleAvanzado ? descripciones : [estudioDetalleSingle.descripcion]}
					unidadMedida={estudioDetalleEdit.estudioDetalleAvanzado ? estudioDetalleEdit.unidadMedida : estudioDetalleSingle.unidadMedida}
					estudioDetalleAvanzado={estudioDetalleEdit.estudioDetalleAvanzado ?? undefined}
					estudioDetalleId={estudioDetalleEdit.id ?? undefined}
					estudioDetalleAvanzadoId={estudioDetalleEdit.estudioDetalleAvanzadoId ?? undefined}
					coeficienteEstudio={coeficienteEstudio ?? 1}
					delegacionId={selectedDelegacion?.id ?? getDelegacion().id}
					readOnly={readOnly}
					estudiosDetalleLength={estudioDetalleEdit.ordenDetalle && estudioDetalleEdit.id != undefined ? estudioDetalleEdit.ordenDetalle : estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) + 1 : 1}

				/>
			case 3:
				return <CalculoAvanzadoZahorra
					openModal={openModal}
					handleCloseModal={handleOpenModalFunc}
					handleCloseCancelModal={cancelEstudioDetalleAvanzado}
					unidadObraId={selectedUnidad?.id ?? estudioDetalleEdit.unidadObraId ?? undefined}
					unidadObraDescripcion={selectedUnidad?.descripcion ?? estudioDetalleEdit.descripcion ?? undefined}
					estudioDetalleDescripcion={estudioDetalleEdit.estudioDetalleAvanzado ? descripciones : [estudioDetalleSingle.descripcion]}
					unidadMedida={estudioDetalleEdit.estudioDetalleAvanzado ? estudioDetalleEdit.unidadMedida : estudioDetalleSingle.unidadMedida}
					estudioDetalleAvanzado={estudioDetalleEdit.estudioDetalleAvanzado ?? undefined}
					estudioDetalleId={estudioDetalleEdit.id ?? undefined}
					estudioDetalleAvanzadoId={estudioDetalleEdit.estudioDetalleAvanzadoId ?? undefined}
					coeficienteEstudio={coeficienteEstudio ?? 1}
					delegacionId={selectedDelegacion?.id ?? getDelegacion().id}
					readOnly={readOnly}
					estudiosDetalleLength={estudioDetalleEdit.ordenDetalle && estudioDetalleEdit.id != undefined ? estudioDetalleEdit.ordenDetalle : estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) + 1 : 1}

				/>
			case 4:
				return <CalculoAvanzadoPlantilla
					openModal={openModal}
					handleCloseModal={handleOpenModalFunc}
					handleCloseCancelModal={cancelEstudioDetalleAvanzado}
					unidadObraId={selectedUnidad?.id ?? estudioDetalleEdit.unidadObraId ?? undefined}
					unidadObraDescripcion={selectedUnidad?.descripcion ?? estudioDetalleEdit.descripcion ?? undefined}
					estudioDetalleDescripcion={estudioDetalleEdit.estudioDetalleAvanzado ? descripciones : [estudioDetalleSingle.descripcion]}
					unidadMedida={estudioDetalleEdit.estudioDetalleAvanzado ? estudioDetalleEdit.unidadMedida : estudioDetalleSingle.unidadMedida}
					estudioDetalleAvanzado={estudioDetalleEdit.estudioDetalleAvanzado ?? undefined}
					estudioDetalleId={estudioDetalleEdit.id ?? undefined}
					estudioDetalleAvanzadoId={estudioDetalleEdit.estudioDetalleAvanzadoId ?? undefined}
					coeficienteEstudio={coeficienteEstudio ?? 1}
					delegacionId={selectedDelegacion?.id ?? getDelegacion().id}
					readOnly={readOnly}
					estudiosDetalleLength={estudioDetalleEdit.ordenDetalle && estudioDetalleEdit.id != undefined ? estudioDetalleEdit.ordenDetalle : estudioDetalleArray.length > 0 ? Math.max(...estudioDetalleArray.map(x => x.ordenDetalle)) + 1 : 1}

				/>
			default:
				return <></>;
		}
	}

	const editarEstudioDetalleNotas = (notas: string | undefined, index: number) => {
		setIndexEstudioNotasSelected(index);
		setEstudioNotasSelected(notas);
		setOpenModalEstudioDetalleNotas(true);
	};
	


	const renderEstudioDetalleRows = (estudioDetalleArray: EstudioDetalle[]) => {
		const indexMBC = estudioDetalleArray.findIndex(x => x.unidadObra?.calculoAvanzadoId === CalculoAvanzadoTipo.MBC);
		estudioDetalleArray.sort((a, b) => a.ordenDetalle - b.ordenDetalle)
		
		return estudioDetalleArray?.map((item: EstudioDetalle, index: number) => (
			<tr key={index}>
				<td>
					<TooltipComponent text={item.descripcion}>
						{item.descripcion}
					</TooltipComponent>
				</td>
				<td>{item.unidadMedida ? item.unidadMedida.descripcion : "SIN UNIDAD"}</td>
				<td>
					<div className='inline-input number'>
						{item.unidadMedida && formatNumber(medicionByUnidadMedida(item))}
					</div>
				</td>
				<td>
					<div className='inline-input number'>
						{formatNumber(item.precioCoste)}
					</div>
				</td>
				<td>
					<div className='inline-input number'>
						{formatNumber(item.importeCoste)}
					</div>
				</td>
				<td>
					<div className='inline-input number'>
						{formatNumber(item.coeficiente)}
					</div>
				</td>
				<td>
					<div className='inline-input number'>
						{formatNumber(item.precioVenta)}
					</div>
				</td>
				<td>
					<div className='inline-input number'>
						{formatNumber(item.importeVenta)}
					</div>
				</td>
				<td>
					<div className="icons-horizontal-inlinegrid">
						<IconButton
							icon={readOnly ? "manage_search" : (item.notas ? "edit_note" : "add")}
							onClick={() => { editarEstudioDetalleNotas(item.notas, index); }}
							disabled={readOnly && !item.notas}
							index={index}
							label="EditarEstudioDetalleNotas"
						/>
					</div>
				</td>
				<td>
					<div className="icons-horizontal-inlinegrid">
						<IconButton
							icon={readOnly ? "visibility" : "edit"}
							onClick={() => { editarDetalleEstudios(item, index); }}
							disabled={showEstudios || (item.unidadObra?.calculoAvanzadoId === CalculoAvanzadoTipo.MBC && index !== indexMBC)}
							index={index}
							label="EditarEstudioModal"
						/>
						{!readOnly && <IconButton
							icon="delete"
							onClick={() => { openConfirmationModal(item.id, index); }}
							disabled={(item.estudioDetalleAvanzado?.material && item.estudioDetalleAvanzado?.material.length > 1) || detallesZero(item, index) || showEstudios}
							index={index}
							label="DeleteEstudioModal"
						/>}
					</div>
				</td>
			</tr>
		));
	};

	return (
		<div>
			<ModalPage
				handleCloseModal={handleRefresh}
				opened={opened}
				fields={
					<>
						<fieldset disabled={readOnly}>
							<div className="padding-bg">
								<h3>Estudio</h3>
								{!estudio.id &&
									<div className="double-col-grid">
										<div className="import-study">
											<label>Importar Estudio</label>
											<input
												onChange={(e) => {
													onCheckImport();
												}}
												type="checkbox"
											/>
											{showOtherOferta === true && <FormInput
												label="Código Oferta"
												value={importarEstudio.toString()}
												onChange={(e) =>
													onCheckImportOtherOferta(e.target.value)
												}
												type={"number"}
												placeholder="Código Oferta"
												idTest=""
											/>
											}
										</div>
										{showEstudios &&
											<CustomDropdownInput
												name="selectEstudio"
												inputLabel="Seleccionar Estudio"
												placeholder="Seleccionar Estudio"
												options={estudios.map((item: any) => (item.estudioId + " - " + item.descripcion))}
												value={(selectedEstudio.estudioId + " - " + selectedEstudio.descripcion).replace("Copia de ", "")}
												onChange={(e: any) => handleSelectEstudio(e.target.value)}
											/>}
									</div>
								}
								<div className="double-col-grid">
									<FormInput
										label="Nombre Estudio"
										value={selectedEstudio.descripcion}
										onChange={(e) => updateCampoEstudio(e.target.value, 'descripcion')}
										required
										error={selectedEstudio.descripcion == '' ? "El nombre del estudio es obligatorio" : ""}
										idTest="NombreEstudioGridModal"
									/>
									<div>
										<FormInput
											label="Nombre para el Presupuesto"
											value={selectedEstudio.descripcionPresupuesto ?? ""}
											onChange={(e) => updateCampoEstudio(e.target.value, 'descripcionPresupuesto')}
										/>
									</div>
								</div>
								<div className="double-col-grid">

									{delegaciones &&
										<FormField<DropdownProps>
											label="Delegación"
											options={delegaciones.filter(x => hasRole(USER_ROLES.ADMIN) || x.id === (selectedDelegacion?.id ?? getDelegacion().id)).map(
												(element) => {
													return {
														text: element.descripcion,
														id: element.id,
														selected: (Number.isNaN(selectedDelegacion?.id) ? Number.parseInt(getDelegacion().id) : selectedDelegacion?.id) == +element.id ? true : false,
														onClick: () => { handleDelegacionSelected(element) }
													}
												})}
											required={true}
											disabled={false}
											singleSelection={true}
											component={Dropdown}
										/>}
									<div className="relative">

										<FormInput
											label="Coeficiente"
											value={coeficienteEstudio?.toString()! ?? estudio.coeficiente?.toString()}
											onChange={(e) => addCoeficienteEstudio(e.target.value)}
											type={"number"}
										/>

										{
											estudio.numeroPresupuestoYVersion == "" && <CustomButton
												id={"add-btn"}
												icon="add"
												title={'Asignar'}
												className="add-btn-right"
												type="button"
												onButtonClick={() => {
													updateCoeficienteEstudio()
												}}
											/>
										}
									</div>
								</div>
							</div>

							{estudio.numeroPresupuestoYVersion == "" &&
								<div className="padding-bg accent-bg">
									<div className="study-detail">
										<h3>Detalle del Estudio
											{estudioDetalleSingle.id ? " (Editando detalle)" : " (Creando detalle)"}
										</h3>
										<CustomButton
											id={"add-btn"}
											icon="add"
											className="add-btn"
											title={'Nuevo estudio'}
											btnStyle="accept"
											type="button"
											onButtonClick={() => {
												crearUnidadObra()
											}}
											disabled={parseInt(customId) >= 5}
										/>
									</div>
									{
										openUnidadObraModal ? <CrearUnidadObra
											openModal={openUnidadObraModal}
											handleCloseModal={handleOpenModalUnidadObra}
											handleAddUnidadObra={addUnidadOfertaCustom}
										/> : ""
									}
									{
										estudioDetalleSingle.unidadObraId != null ?
											<div className="row-of-three">
												<FormField<DropdownProps>
													label="Unidad de Obra"
													options={unidades.map(
														(element) => {
															return {
																text: element.descripcion,
																id: element.id,
																selected: selectedUnidad?.id == element.id,
																onClick: () => { handleSelectUnidadObra(element); }
															}
														})}
													required={true}
													disabled={false}
													singleSelection={true}
													component={Dropdown}
												/>
												<FormField<DropdownProps>
													label="Medida"
													options={medidas.map(
														(element) => {
															return {
																text: element.descripcion,
																id: element.id,
																selected: +element.id === newMedida.id,
																onClick: () => { handleSelectMedida(element) }
															}
														})}
													required={true}
													disabled={false}
													singleSelection={true}
													component={Dropdown}
												/>
												{
													((selectedUnidad && newMedida && descipcionUnidad && descipcionUnidad.length > 0) || newUnidadObraCustom) &&
													<div className="input-with-btn">
														<FormField<DropdownProps>
															label="Descripción"
															options={descipcionUnidad.map(
																(element) => {
																	return {
																		text: element.descripcion,
																		id: element.id ? element.id : "",
																		selected: estudioDetalleSingle?.descripcion == element.descripcion,
																		onClick: () => { handleChangeDescription(element); }
																	}
																})}
															required={true}
															disabled={selectedUnidad ? false : true}
															singleSelection={true}
															component={Dropdown}

														/>

														{(selectedUnidad?.calculoAvanzadoId ?? 0) >= 1 &&
															estudioDetalleSingle.descripcion != "" &&
															descipcionUnidad.length > 0 &&
															selectedEstudio.descripcion != "" &&
															<IconButton
																icon="add"
																className="add-btn"
																onClick={() => { openModelCalculoAvanzado(); }}
															/>
														}
													</div>
												}
											</div>
											:
											""
									}

									<div className="double-col-grid">
										<CustomInput
											label="Descripción"
											resizable
											value={estudioDetalleSingle.descripcion}
											onChange={(e) => updateDescripcion(e)}
											required
											disabled={selectedUnidad?.calculoAvanzadoId === 1 || selectedUnidad?.calculoAvanzadoId === 2 || selectedUnidad?.calculoAvanzadoId === 3 || selectedUnidad?.calculoAvanzadoId === 4}
											idCypress="DescripcionModalOfertas"
										/>

										<div className="flex-horizontal">
											<FormInput
												label="U. Medida"
												value={estudioDetalleSingle.unidadMedida ? estudioDetalleSingle.unidadMedida.descripcion : ""}
												onChange={(e) => { }}
												disabled
												required
												idTest="UnidadMedidaModalOfertas"
											/>
											<FormInput
												label="Medición"
												type="number"
												min={-999999999}
												value={estudioDetalleSingle.medicion.toString()}
												onChange={(e) => updateMedicion(e.target.value)}
												required={selectedUnidad?.calculoAvanzadoId !== 1}
												disabled={selectedUnidad?.calculoAvanzadoId === 1 || selectedUnidad?.calculoAvanzadoId === 2 || selectedUnidad?.calculoAvanzadoId === 3|| selectedUnidad?.calculoAvanzadoId === 4}
												idTest="MedicionModalOfertas"
											/>

											<FormInput
												label="Coeficiente"
												value={estudioDetalleSingle.coeficiente.toString()}
												onChange={(e) => updateCoeficiente(e.target.value)}
												required={selectedUnidad?.calculoAvanzadoId !== 1}
												type="number"
												disabled={selectedUnidad?.calculoAvanzadoId === 1 || selectedUnidad?.calculoAvanzadoId === 2 || selectedUnidad?.calculoAvanzadoId === 3|| selectedUnidad?.calculoAvanzadoId === 4}
												idTest="CoeficienteModalOfertas"
											/>
										</div>
									</div>
									<h3>Costes y Ventas</h3>
									<div className="one-row">
										<FormInput
											label="Precio Coste"
											value={estudioDetalleSingle.precioCoste.toString()}
											onChange={(e) => updatePrecioCoste(e.target.value)}
											required={selectedUnidad?.calculoAvanzadoId !== 1}
											type="number"
											min={-999999999}
											disabled={selectedUnidad?.calculoAvanzadoId === 1 || selectedUnidad?.calculoAvanzadoId === 2 || selectedUnidad?.calculoAvanzadoId === 3|| selectedUnidad?.calculoAvanzadoId === 4}
											idTest="PrecioCosteModalOfertas"
										/>
										<FormInput
											label="Importe coste"
											value={formatNumber(estudioDetalleSingle.importeCoste)}
											onChange={(e) => { }}
											required={selectedUnidad?.calculoAvanzadoId !== 1}
											disabled
											type="text"
											idTest="ImporteCosteModalOfertas"
										/>

										<FormInput
											label="Precio Venta"
											value={formatNumber(estudioDetalleSingle.precioVenta)}
											onChange={(e) => updatePrecioVenta(e.target.value)}
											required={selectedUnidad?.calculoAvanzadoId !== 1}
											disabled
											type="text"
											idTest="PrecioVentaModalOfertas"
										/>
										<FormInput
											label="Importe Venta"
											value={formatNumber(estudioDetalleSingle.importeVenta)}
											onChange={(e) => { }}
											required={selectedUnidad?.calculoAvanzadoId !== 1}
											disabled
											type="text"
											idTest="ImporteVentaModalOfertas"
										/>
									</div>
									<div className="buttons-right">
										<CustomButton
											key={`confirmAddDetalleModal`}
											text="Guardar"
											title={'Añadir detalle'}
											className={'btn-primary'}
											btnStyle="accept"
											onButtonClick={() => { saveEstudioDetalle([estudioDetalleSingle]); }}
											type="button"
											disabled={
												(estudioDetalleSingle.unidadMedida.id == undefined && !selectedUnidad) || !newMedida || !estudioDetalleSingle?.descripcion ||
												selectedUnidad?.calculoAvanzadoId == 1 || selectedUnidad?.calculoAvanzadoId == 2 || selectedUnidad?.calculoAvanzadoId == 3 || selectedUnidad?.calculoAvanzadoId == 4 ||
												(estudioDetalleSingle.coeficiente == 0 || estudioDetalleSingle.precioVenta == 0 /*|| medicionZero(estudioDetalleSingle.medicion)*/) ||
												estudioDetalleSingle?.descripcion === "" || estudioDetalleSingle?.descripcion == null ||
												(estudioDetalleSingle.unidadObraId != null && descipcionUnidad.length == 0) || !descripcionUnidadObraSelected 
												|| medicionNull == null
											}
										/>
									</div>
								</div>
							}
						</fieldset>
						<div className="padding-bg">
							<h3>Descripción del Estudio</h3>
							<table className="mini-table">
								<thead>
									<tr className='table-header'>
										<th>Descripción de la unidad de obra</th>
										<th>Uds</th>
										<th>Medición</th>
										<th>Precio Costes</th>
										<th>Importe Costes</th>
										<th>Coeficiente</th>
										<th>Precio Ventas</th>
										<th>Importe Ventas</th>
										<th>Notas</th>
										<th>
											<div className="icons-horizontal-inlinegrid">
												{estudioDetalleArray.filter(x => x.estudioDetalleAvanzadoId != null).length > 0 ?
													<>
														<IconButton
															icon="insert_drive_file"
															onClick={() => { exportExcel() }}
															disabled={showEstudios}
															className=""
														/>
														{estudio.numeroPresupuestoYVersion == "" &&
															<IconButton
																icon="format_list_numbered"
																onClick={() => { estudioDetalleOrden() }}
																disabled={showEstudios}
																className=""
															/>
														}
													</>
													:
													estudioDetalleArray.length > 0 ?
														<>
															{estudio.numeroPresupuestoYVersion == "" &&
																<IconButton
																	icon="format_list_numbered"
																	onClick={() => { estudioDetalleOrden() }}
																	disabled={showEstudios}
																	className=""
																/>
															}
														</>
														: ""
												}
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{estudioDetalleArray && renderEstudioDetalleRows(estudioDetalleArray)}
									<tr>
										<td colSpan={3}></td>
										<td>Total Costes</td>
										<td>
											<div className='inline-input number'>
												{formatNumber(+totalCostes.toFixed(2))}
											</div>
										</td>
										<td></td>
										<td>
											<TooltipComponent text={"Total Ventas"}>
												Total Ventas
											</TooltipComponent>
										</td>
										<td>
											<div className='inline-input number'>
												{formatNumber(+totalVentas.toFixed(2))}
											</div>
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>

						</div>

						<div className="buttons-horizontal" style={{ width: 'calc(100% - 48px)', paddingLeft: '24px', }}>
							{
								!readOnly && !estudio.numeroPresupuestoYVersion && estudioDetalleArray?.find(x => x.estudioDetalleAvanzado) ?

									<CustomButton
										key="btnActualizarTarifas"
										title="Actualizar tarifas"
										className={'btn-primary'}
										btnStyle="accept"
										onButtonClick={() => { setOpenModalActualizarTarifas(true); }}
										type="button"
										text="Actualizar tarifas"
										disabled={!estudio.id}
									/>
									: <div></div>
							}
							<CustomButton
								key={`confirmSaveEstudio`}
								title={estudioDetalleArray.length == 0 ? 'Complete el estudio para poder finalizarlo' : 'Finalizar'}
								className={'btn-primary'}
								btnStyle="accept"
								onButtonClick={() => { saveEstudio(); }}
								type="button"
								text="Finalizar"
								disabled={readOnly || estudioDetalleArray.length == 0 || selectedEstudio.descripcion == "" || selectedEstudio.descripcion == null || selectedEstudio == undefined || medicionZero(+totalVentas.toFixed(3), true)}
							/>
						</div>
					</>
				}

				modalTitle={estudio.id ? (readOnly || estudio.numeroPresupuestoYVersion != "" ? "Estudio" : "Editar estudio") : "Añadir nuevo estudio"}
				bodyClassName='--large'
			>
			</ModalPage>

			{
				(openDelete) &&
				<DeleteModal
					open={openDelete}
					handleCloseModal={handleCloseModalDelete}
					field="detalle del estudio"
				>
				</DeleteModal>
			}

			{
				openEstudioDetalleOrden ? <EstudioDetalleOrden
					openModal={openEstudioDetalleOrden}
					handleCloseModal={handleOpenEstudioDetalleOrden}
					handleCancelModal={handleOpenEstudioDetalleOrdenCancel}
					estudioDetalleArray={estudioDetalleArray}
				/> : ""
			}

			<Modal
				className="filters-modal modal-l"
				show={openModalActualizarTarifas}
				onHide={() => setOpenModalActualizarTarifas(false)}>
				<ModalHeader>
					<ModalTitle>
						Actualizar tarifas
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<p>Al actualizar las tarifas, los valores anteriores no se podrán recuperar.</p>
					<p>¿Está seguro?</p>
					<div className="bottom-buttons">
						<CustomButton
							key={`removeCancelModal`}
							title={'Cancelar'}
							className={'btn-primary'}
							onButtonClick={() => { setOpenModalActualizarTarifas(false); }}
							text="No"
						/>
						<CustomButton
							key={`confirmDeleteModal`}
							title={'Borrar'}
							className={'btn-primary'}
							btnStyle="delete"
							onButtonClick={() => { updateTarifas(); }}
							text="Sí"
						/>
					</div>
				</ModalBody>
			</Modal>

			<Modal
				className="filters-modal modal-l"
				show={openModalEstudioDetalleNotas}
				onHide={() => setOpenModalEstudioDetalleNotas(false)}
			>
				<ModalHeader>
					<ModalTitle>
						Editar notas
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div>
						<EditorProvider>
							<Editor containerProps={{ style: { maxHeight: '500px', maxWidth: "700px", overflow: "auto" } }}
								value={estudioNotasSelected ?? ""} onChange={(e) => { setEstudioNotasSelected(e.target.value) }}>
								<Toolbar>
									<BtnUndo />
									<BtnRedo />
									<Separator />
									<BtnBold />
									<BtnItalic />
									<BtnUnderline />
									<BtnStrikeThrough />
									<Separator />
									<BtnNumberedList />
									<BtnBulletList />
									<Separator />
									<BtnLink />
									<BtnClearFormatting />
									<HtmlButton />
									<Separator />
									<BtnStyles />
								</Toolbar>
							</Editor>
						</EditorProvider>
						{/* <CustomInput
						label="Notas"
						value={estudioNotasSelected ? estudioNotasSelected : ""}
						onChange={(e) => setEstudioNotasSelected(e) }
					/> */}
					</div>
					<div className="bottom-buttons">
						{!readOnly && <CustomButton
							key={`confirmModalEstudioDetalleNotas`}
							title={'Aceptar'}
							className={'btn-primary'}
							onButtonClick={() => { updateNotas(); }}
							text="Aceptar"
						/>}
						<CustomButton
							key={`cancelModalEstudioDetalleNotas`}
							title={'Cancelar'}
							className={'btn-primary'}
							onButtonClick={() => { setOpenModalEstudioDetalleNotas(false); }}
							text="Cancelar"
						/>
					</div>
				</ModalBody>
			</Modal>

			{
				openModal ?
					estudioAvanzadoComponent()
					:
					""
			}
		</div>
	)
}

export default EstudiosOfertasModalSection;