import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalPage from "../../../../components/ui/atoms/ModalPage/ModalPage";
import { useLoading } from "../../../../context/Loading.Context";
import OfertasApiRepository from "../../../Ofertas/infraestructure/api/Ofertas.ApiRepository";
import OfertasRepository from "../../../Ofertas/domain/OfertasRepository";
import Licitacion from "../../domain/model/Licitacion";
import { AuthContext } from "../../../Login/AuthContextProvider";
import useOfertasForm, { OfertasFormDto } from "../../../Ofertas/hooks/useOfertasForm";
import { Routes } from "../../../../router/routes/Routes";
import { HistoryHandler } from "../../../../context/History.Context";
import { OfertasCreationDto, TipoOfertaEnum } from "../../../Ofertas/domain/model/Ofertas";
import { formatDate2String } from "../../../../utils/DateUtil";
import FormInput from "../../../../components/ui/atoms/Input/FormInput/FormInput";
import BottomButtons from "../../../../components/ui/molecules/Bottom-buttons/BottomButtons";
import LabelButton from "../../../../components/ui/atoms/Buttons/LabelButton";
import FormField from "../../../../components/ui/molecules/Form-field/FormField";
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from "../../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field";
import ClienteDetalleRepository from "../../domain/ClienteDetalleRepository";
import ClienteDetalleApiRepository from "../../infraestructure/api/ClienteDetalle.ApiRepository";
import CodigoPostalApiRepository from "../../../../infraestructure/api/CodigoPostal.ApiRepository";
import AdjudicacionRepository from "../../../../domain/AdjudicacionRepository";
import AdjudicacionApiRepository from "../../../../infraestructure/api/Adjudicacion.ApiRepository";
import Cliente from "../../../Clientes/domain/model/Cliente";
import ClienteRepository from "../../../Clientes/domain/ClienteRepository";
import ClienteApiRepository from "../../../Clientes/infraestructure/api/Cliente.ApiRepository";
import ClienteRepositoryDom from "../../domain/ClienteRepository";
import ClienteApiRepositoryInfra from "../../infraestructure/api/Cliente.ApiRepository";
import { PAVASAL_CLIENTEID } from "../../../../assets/constants/Constantes";
import OportunidadesOfertaTemplate from "./Oportunidades.OfertaTemplate";





interface Props {
    openModal: boolean,
    //TODO: handleclose va a ser un hiperlink (poner en la funcion de hiperlink)
    handleCloseModal: () => void,
    handleCancelModal: () => void,
    idLicitacion: string
    onSubmit?: (data: OfertasFormDto) => void
}

const OportunidadesOfertaModal: React.FC<Props> = ({ openModal, handleCloseModal, handleCancelModal, idLicitacion, onSubmit }) => {

    const { setLoading } = useLoading();
    const { getToken } = useContext(AuthContext);
    const { goToRoute } = useContext(HistoryHandler);


    const [codLicitacion, setCodLicitacion] = useState<string>(idLicitacion.split("-")[1].trim());
    const [licitacion, setLicitacion] = useState<Licitacion>();
    const [clienteLicitacion, setClienteLicitacion] = useState<Cliente>();

    const fetchAdjudicacion = useCallback((codigo: number) => {
        const repoAdjudicaciones: AdjudicacionRepository = new AdjudicacionApiRepository(getToken())
        const repoCliente: ClienteRepository = new ClienteApiRepository(getToken());

        repoAdjudicaciones.fetchAdjudicacion(codigo).then(respAdjudicacion => {
            if (respAdjudicacion && respAdjudicacion.length > 0) {
                let asfaltero = respAdjudicacion;

                if (respAdjudicacion.length > 1) {
                    asfaltero = respAdjudicacion.filter(p => p.asfaltero == "0");
                    if (asfaltero.length === 0) asfaltero = respAdjudicacion;
                }

                if (asfaltero[0].cif.trim() != "") {
                    repoCliente.fetchClienteByCif(asfaltero[0].cif.trim()).then(respCliente => {
                        setClienteLicitacion(respCliente);

                        if (!respCliente) {
                            toast.info("El cliente de la licitación no se ha podido asociar automáticamente con el cliente de la oferta.");
                        }
                    });
                } else {
                    toast.info("El cliente de la licitación no se ha podido asociar automáticamente con el cliente de la oferta.");
                }
            }
        });
    }, [clienteLicitacion]);

    const fetchLicitacion = (codigo: number) => {
        //TODO: el setLoading hace que no vaya (mirar porque)
        // setLoading(true);
        const repo: OfertasRepository = new OfertasApiRepository(getToken());
        setLicitacion(undefined);
        setClienteLicitacion(undefined);

        repo.getLicitacionByCodigo(codigo).then(resp => {
            if (resp.estado === "ANULADA") {
                toast.error("La licitación no puede estar anulada");
            } else {
                setLicitacion(resp);
                fetchAdjudicacion(resp.codigo);

                if (resp.estadoId == 3) {
                    toast.info("El tipo de la Oferta actual se cambiará a Interna automáticamente al ser el cliente PAVASAL.");
                }
            }
        })
            .catch((error) => {
                toast.error("No existe licitación asociada al código introducido");
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    const fetchCreation = useCallback((data: OfertasCreationDto) => {
        // setLoading(true);
        const repo: OfertasRepository = new OfertasApiRepository(getToken());
        repo.addOfertas(data)
            .then((res) => {
                toast.success("Oferta creada con éxito");
                handleCloseModal()
                window.open("/ofertas/edicion/"+res?.id );
            })
            .catch(error => {
                toast.error(error);
            })
            .finally(() => handleCloseModal );
    }, [goToRoute]);

    useEffect(() => {
        fetchLicitacion(+codLicitacion)
    }, [codLicitacion])


    return (
        <div className="modal-section">
            <div className="fields-modal-container">
                <ModalPage
                    className="filters-modal modal-l"
                    bodyClassName="--medium"
                    handleCloseModal={handleCloseModal}
                    handleCloseModalCustom={handleCloseModal}
                    opened={openModal}
                    modalTitle="Agregar Oferta"
                    // hideClose={true}
                    fields={
                        <>
                            <div className="add-edit-view-container">
                                {
                                    licitacion && licitacion?.codigo && clienteLicitacion &&
                                    <OportunidadesOfertaTemplate
                                        licitacion={{ ...licitacion, promotorId: licitacion?.promotorId.toString() }}
                                        clienteLicitacion={clienteLicitacion}
                                        onSubmit={fetchCreation}
                                    />
                                }
                                {licitacion && licitacion?.codigo && !clienteLicitacion &&
                                    <OportunidadesOfertaTemplate
                                        licitacion={{ ...licitacion, promotorId: licitacion?.promotorId.toString() }}
                                        onSubmit={fetchCreation}
                                    />
                                }

                            </div>
                        </>
                    }
                >
                </ModalPage>
            </div>
        </div>
    );
}


export default OportunidadesOfertaModal;