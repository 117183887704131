import RowMapper from "../../../../components/TableGridView/model/RowMapper";
import { formatDate2String } from "../../../../utils/DateUtil";
import { formatNumber } from "../../../../utils/NumberUtil";
import Oportunidad from "../../domain/model/Oportunidad";

class OportunidadesRow extends RowMapper<Oportunidad> {
	objectMap(item: Oportunidad): Map<keyof Oportunidad, string> {
		return new Map<keyof Oportunidad, string>	(
			[
				["codigo", item.codigo.toString()],
				["fechaRegistro", formatDate2String(item.fechaRegistro)],
				["promotorDescripcion", item.promotorDescripcion],
				["toneladas", formatNumber(item.toneladas ?? 0) ?? ""],
				["provinciaDescripcion", item.provinciaDescripcion],
				["municipioDescripcion", item.municipioDescripcion],
				["contratistaDescripcion", item.contratistaDescripcion],
				["obraDescripcion", item.obraDescripcion],
				["asfaltera", item.asfaltera],
				["licitamos", item.licitamos],
				["estadoOportunidadDescripcion", item.estadoOportunidadDescripcion],
				["tipoOportunidadDescripcion", item.tipoOportunidadDescripcion]
			]
		)
	}

}

export default OportunidadesRow;