import { useContext } from "react";
import FilterModal from "../../../../components/ui/atoms/FilterModal/FilterModal";
import { GlobalContext, GlobalContextType } from "../../../../context/Global.Context";
import OportunidadesTagCloud from "../../OportunidadesTagCloud";
import FilterFieldsProvider from "../../context/FilterFields.Provider";
import { OportunidadesDataContext } from "../../context/Oportunidades.Context";
import AsfalteroDropdown from "../atoms/AsfalteroOptions.Dropdown";
import CodigoLicitacionInput from "../atoms/CodigoLicitacion.Input";
import CodigoOfertaInput from "../atoms/CodigoOferta.Input";
import CodigoOportunidadInput from "../atoms/CodigoOportunidad.Input";
import DelegacionesDropdown from "../atoms/Delegaciones.Dropdown";
import EstadoOportunidadDropdown from "../atoms/EstadoOportunidad.Dropdown";
import FechaAdjudicacionDateSelector from "../atoms/FechaAdjudicacion.DateSelector";
import FechaRegistroDateSelector from "../atoms/FechaRegistro.DateSelector";
import LicitamosDropdown from "../atoms/LicitamosOptions.Dropdown";
import ObraDescripcionSuggestionInput from "../atoms/SuggestionInputs/ObraDescripcion.SuggestionInput";
import PromotorSuggestionInput from "../atoms/SuggestionInputs/Promotor.SuggestionInput";
import ProvinciaSuggestionInput from "../atoms/SuggestionInputs/Provincia.SuggestionInput";
import TipoOportunidadDropdown from "../atoms/TipoOportunidad.Dropdown";
import ToneladasMinMax from "../atoms/Toneladas.MinMax";
import ContratistaFilter from "../molecules/ContratistaFilter/ContratistaFilter";
import OportunidadesTags from "../molecules/Oportunidades.Tags";

const OportunidadesFilterSection: React.FC<{}> = () => {
	const {downloadExcel, downloadExcelDetallado} = useContext(OportunidadesDataContext);

	const { globalState } = useContext(GlobalContext) as GlobalContextType;
	

	const excludeTipoOportunidadDropdown=()=>{
		/*
		if (globalState && globalState?.oportunidades?.filters?.estadoOportunidadIds?.length>0 && globalState.oportunidades?.filters?.estadoOportunidadIds[0]==2)
		{
			return 1;
		};

		if (globalState && globalState?.oportunidades?.filters?.estadoOportunidadIds?.length>0 &&  globalState.oportunidades?.filters?.estadoOportunidadIds[0]==5)
		{
			return 2;
		}
		*/
		return undefined;
	}

	const excludeEstadoOportunidadDropdown=()=>{	
	/*
		console.log(globalState?.oportunidades?.filters);
		if ( globalState && globalState?.oportunidades?.filters?.tipoOportunidadId?.length>0 && globalState.oportunidades?.filters?.tipoOportunidadId[0]==1)
		{
			return 2;
		}
		
		if (globalState && globalState?.oportunidades?.filters?.tipoOportunidadId?.length>0 && globalState.oportunidades?.filters?.tipoOportunidadId[0]==2)
			{
				return 5;
			}
		return undefined;
		*/
		return undefined;
	}

	return (
		<FilterFieldsProvider>
			{/* <div className="filter-section"> */}
			<div className="filters-container">
				<div className="filters-modal-container">
					<FilterModal filters={
						<>
							<PromotorSuggestionInput />
							<ObraDescripcionSuggestionInput idTest="ObraFiltro"/>
							<ProvinciaSuggestionInput />
							<DelegacionesDropdown idTest="DelegacionFiltro"/>
							<ContratistaFilter />
							<AsfalteroDropdown idTest="AsfalteraFiltro"/>
							<EstadoOportunidadDropdown excludedId={excludeEstadoOportunidadDropdown()} idTest="EstadoOportunidadFiltro"/>
							<TipoOportunidadDropdown excludedId={excludeTipoOportunidadDropdown()} idTest="TipoOportunidadFiltro"/>
							<LicitamosDropdown idTest="LicitamosFiltro" />
							<FechaRegistroDateSelector idTest="FechaRegistroFiltro"/>
							<FechaAdjudicacionDateSelector idTest="FechaAdjudicacionFiltro"/>
							<ToneladasMinMax />
							<CodigoOportunidadInput idTest="CodigoOportunidadFiltro"/>
							<CodigoLicitacionInput idTest="CodigoLicitacionFiltro"/>
							<CodigoOfertaInput idTest="CodigoOfertaFiltro"/>
						</>
					}
						titleShow="Filtros"
						modalTitle="Selección de filtros"
						//modalSubtitle="Introduzca los parámetro para filtrar"
						downloadExcel={downloadExcel}
						downloadExcelDetallado={downloadExcelDetallado}
						label = {"TituloModal"}
						oportunidades={true}
					>
					</FilterModal>
					{/* <OportunidadesTagCloud /> */}
				</div>
				<OportunidadesTags />
			</div>
		</FilterFieldsProvider>
	)
}

export default OportunidadesFilterSection;