import { FC, useContext, useEffect, useState } from "react";
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context";
import { BaseFilter } from "../../../domain/BaseFilter";
import useColumnasGrid from "../../../hooks/useColumnasGrid";
import { AuthContext } from "../../Login/AuthContextProvider";
import usePomotoresOfertasTags from "../../Ofertas/hooks/useOfertasTags";
import { OportunidadFilter } from "../domain/model/Oportunidad";
import OportunidadesRepository from "../domain/OportunidadesRepository";
import useFilters from "../hooks/useFilters/useFilters";
import useOportunidadesPost from "../hooks/useOportunidadesPost";
import useOportunidadesSumaToneladasPost from "../hooks/useOportunidadesSumaToneladasPost";
import OportunidadesApiRepository from "../infraestructure/api/Oportunidades.ApiRepository";
import {
	LicitacionesColumnsContext,
	OportunidadesDataContext,
	OportunidadesFilterHandlersContext,
	OportunidadesPagination,
	//OportunidadesFilterContext,
	OportunidadesSearchHandler,
	OportunidadesTagHandler,
	OportunidadesTags
} from "./Oportunidades.Context";
import { useLoading } from "../../../context/Loading.Context";

const BACKEND_OPORTUNIDADES = "BACKENDOPORTUNIDADES";
const OPORTUNIDAD = "OPORTUNIDAD";
const LICITACION = "LICITACION";

const OportunidadesProvider: FC<{}> = ({ children }) => {
	const { columns } = useColumnasGrid(BACKEND_OPORTUNIDADES, OPORTUNIDAD);
	const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
	const { columns: licitacionesColumns } =
		useColumnasGrid(BACKEND_OPORTUNIDADES, LICITACION);
	const defaultSorting = [{ field: "fechaRegistro", desc: true }];
	const globalFilters = globalState?.oportunidades?.filters;
	const queryParameters = new URLSearchParams(window.location.search).get("filter")
	const {userPreferences} = useContext(AuthContext);
	
		
	const {
		filter,
		sorting,
		onSelectField,
		onSearchField,
		onDateField,
		onRangeField,
		onReset,
		onSetMaxCount,
		onSort,
		onPageSelect,
		onPageBackwards,
	} = useFilters<OportunidadFilter>({ 
		sorting: defaultSorting,
		filtering: {
			filter: queryParameters ? {codigo: String(queryParameters)} : globalFilters ?? {},
			// filter: queryParameters ? {codigo: String(queryParameters)} : globalFilters ? globalFilters : {},
			maxResultCount: userPreferences?.json?.maxPagination??10, //globalState?.oportunidades?.pagination?.maxResultCount ?? 10,
			skipCount:  globalState?.oportunidades?.pagination?.skipCount ?? 0,
			sortingCriteria: globalState?.oportunidades?.order?.sortingCriteria ? (globalState?.oportunidades?.order?.sortingCriteria + globalState?.oportunidades?.order?.desc) : "",
		}
	 });

	const {getToken} = useContext(AuthContext);
	const { setLoading } = useLoading();

	const downloadExcel = () => {
		setLoading(true);
		const repo: OportunidadesRepository = new OportunidadesApiRepository(getToken());
		repo.fetchOportunidadesDownloadExcel(filter).then((data)=>{
			window.open(data);
		}).finally(()=>setLoading(false));
	}
	
	const downloadExcelDetallado = () => {
		setLoading(true);
		const repo: OportunidadesRepository = new OportunidadesApiRepository(getToken());
		repo.fetchOportunidadesDownloadExcelDetallado(filter).then((data)=>{
			window.open(data);
		}).finally(()=>setLoading(false));
	}
	const [updatedFilter, setUpdatedFilter] = useState<BaseFilter<OportunidadFilter>>(globalState?.oportunidades?.filters && globalState.oportunidades.filters?.codigoOportunidad ?
		{...filter, filter: {codigo: String(globalState.oportunidades.filters?.codigoOportunidad)}} as any :
		{...filter});

		const { tags, updateTags } = usePomotoresOfertasTags();
	
    useEffect(() => {
		setUpdatedFilter(globalState?.oportunidades?.filters != undefined && globalState.oportunidades.filters?.codigoOportunidad != undefined ?
			{filter: {codigo: String(globalState.oportunidades.filters?.codigoOportunidad)}} as any :
			{...filter});
			if(queryParameters)
			{
				updateTags({label:"codigo", text:queryParameters??"",onClear:()=>{window.location.replace("oportunidades")}})
			}
		
	 }, [filter])

	 useEffect(() => {

		updateGlobalState("oportunidades", {filters: updatedFilter.filter})
		updateGlobalState("oportunidades", {tags: tags})

	 }, [updatedFilter])

	const oportunidades = useOportunidadesPost(updatedFilter);

	const oportunidadesSumaToneladas = useOportunidadesSumaToneladasPost(updatedFilter);
	
	return (
			<OportunidadesDataContext.Provider value={
				{ columns, data: oportunidades, sumaToneladas:oportunidadesSumaToneladas, downloadExcel:downloadExcel, downloadExcelDetallado: downloadExcelDetallado }}>
				<LicitacionesColumnsContext.Provider value={{ columns: licitacionesColumns }}>
					<OportunidadesSearchHandler.Provider value={{ onSearchField }}>
						<OportunidadesTags.Provider value={{tags}}>
							<OportunidadesTagHandler.Provider value={{ onFilter: updateTags }}>
								<OportunidadesPagination.Provider value={{ sorting, maxResultCount: updatedFilter.maxResultCount, onPageBackwards, onPageSelect, onSetMaxCount, onSort }}>
									<OportunidadesFilterHandlersContext.Provider value={
										{
											onSelectField,
											onSearchField,
											onRangeField,
											onDateField,
											onReset,
										}}>
											{children}
									</OportunidadesFilterHandlersContext.Provider>
								</OportunidadesPagination.Provider>
							</OportunidadesTagHandler.Provider>
						</OportunidadesTags.Provider>
					</OportunidadesSearchHandler.Provider>
				</LicitacionesColumnsContext.Provider>
			</OportunidadesDataContext.Provider>
	)
}

export default OportunidadesProvider;
