import { useState } from "react"
import { SingleSuggestionInput } from "./SingleSuggestionInput"
import useSearchCallback from "./hooks/useSearchCallback"
import useSingleSelectorItems from "./hooks/useSingleSelectorItems"


export interface SingleSuggestionInputFieldProps {
	value?: { id: string, text: string }
	disabled?: boolean
	className?: string
	idTest?: string
	onChange: (value: any) => void
	searchCallback: (search: string) => Promise<any>
	set?: ()=>void
	required?: boolean
	banco?: boolean
}

const SingleSuggestionInputField: React.FC<SingleSuggestionInputFieldProps> = (
	{
		value,
		disabled,
		idTest,
		className,
		onChange,
		searchCallback,
		required,
		banco
	}
) => {
	const [changed, setChanged] = useState<boolean>(false);
	const handleChanged = (value: boolean) => {
		setChanged(value);
		if (value) {
			onChange([]);
		}
	}
	const { input, results, onInputChange } = useSearchCallback(searchCallback, value?.text);
	const { suggestions, text, selected } = useSingleSelectorItems(results, ((value && value.id != undefined) ? changed ? [] : [value.id]
	: []), onChange, handleChanged);
	
																		
	return (
		<SingleSuggestionInput		
			input={(selected)? text != "" ? text : input : input}
			required={required}
			disabled={disabled}
			className={className}
			suggestions={suggestions}
			onInputChange={(e) => {
				setChanged(true);
				onInputChange(e);
			}
				}
			cleanButton={true}
			banco={banco}
			idTest={idTest}
		/>
	)
}

export default SingleSuggestionInputField;